




























































































































































































































import { PropType } from "vue";
import { computed, defineComponent, inject, ref } from "@/composition-api";
import AHPlayButton from "@/components/AHPlayButton/AHPlayButton.vue";
import useNavigation from "@/composition/useNavigation";
import useBreakpoints from "@/composition/useBreakpoints";
import useSharingOptions from "@/composition/useSharingOptions";
import useGetUserRole from "@/composition/useGetUserRole";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiHighlight from "@/components/ui/UiHighlight.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiBadge from "@/components/ui/UiBadge.vue";
import UiIconDownload from "@/assets/vectors/icons/download.svg?inline";
import UiIconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import UiIconShare from "@/assets/vectors/icons/link-2-outline.svg?inline";
import UiIconDelete from "@/assets/vectors/icons/delete.svg?inline";
import UiIconEdit from "@/assets/vectors/icons/edit.svg?inline";
import UiIcon from "@dpa-id-components/ui-icon";
import store from "@/store";
import AHAudioDownload from "@/components/AHAudioDownload/AHAudioDownload.vue";
import updateSearch from "./updateKeyWordSearch";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Keywords, MoreButton } from "./parts";
import { getAudioIcon } from "./shared/utils";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useGuid from "./shared/useGuid";
import AHAudioItemContextMenu from "./AHAudioItemContextMenu.vue";
import useAudioItemOptions from "@/composition/useAudioItemOptions";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import router from "@/router";
import useAuthentication from "@/composition/useAuthentication";
import ManagePlaylistDialogEventBus from "@/components/AHPlaylist/AHAddToPlaylistDialogEventBus";

export default defineComponent({
  components: {
    AHAudioItemContextMenu,
    AHAudioDownload,
    AHAudioDelete: () =>
      import(
        /* webpackChunkName: "AHAudioDelete" */ "@/components/AHAudioDelete/AHAudioDelete.vue"
      ),
    AHAudioEdit: () =>
      import(
        /* webpackChunkName: "AHAudioDelete" */ "@/components/AHAudioEdit/AHAudioEdit.vue"
      ),
    AHPlayButton,
    Keywords,
    MoreButton,
    UiBadge,
    UiButtonIcon,
    UiHighlight,
    UiIcon,
    UiIconDownload,
    UiIconShare,
    UiIconDelete,
    UiIconEdit,
    UiIconSpinner,
    UiTooltip
  },
  props: {
    audioItem: {
      type: Object as PropType<ViewModel.AudioItem>
    },
    rawAudioItem: {
      type: Object as PropType<ViewModel.AudioItem>
    }
  },
  setup(props, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const audioContent = computed(() => props.audioItem.audioContent);
    const headline = computed(() => props.audioItem.headline);
    const guid = computed(() => props.audioItem.guid);
    const keywords = computed(() => props.audioItem.keywords);
    const persons = computed(() => props.audioItem.persons);
    const type = computed(() => props.audioItem.type);
    const waveform = computed(() => props.audioItem.waveform);
    const content_created = computed(() => props.audioItem.content_created);
    const highlights = computed(() => props.audioItem.highlights);
    const isFavorite = computed(() => props.rawAudioItem.isFavorite);
    const showFavoriteOption = computed(
      () =>
        store.state.stageconfig.config &&
        store.state.stageconfig.config.features.enableFavorites
    );
    const audioIcon = computed(() => getAudioIcon(type.value));
    const { query } = useRouter(router)();

    const hasPlaylistId = computed(() => {
      return !!query.value.playlistId;
    });
    const { isLeftNavOpen } = useNavigation();
    const { screen, isMobile, isDesktop } = useBreakpoints();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { share, downloadIsLoading } = useSharingOptions({
      guid,
      audioContent,
      headline
    });

    const {
      editAudioItem,
      deleteAudioItem,
      createPlaylist,
      toggleFavorites
    } = useAudioItemOptions({ guid, headline });

    const favoritesMatomo = computed(() => {
      return !isFavorite.value
        ? useMatomoEvent({
            category: "List View",
            action: 'Unclick "Add to favorites" in List View',
            name: ` Remove from favorites in List View`
          })
        : useMatomoEvent({
            category: "List View",
            action: 'Click "Add to favorites" in List View',
            name: `Add to favorites in List View`
          });
    });

    const toggleFavorite = () => {
      const { validateAndRetrieveToken } = useAuthentication();

      toggleFavorites(validateAndRetrieveToken(), props.rawAudioItem);
      eventBus.$emit("show", {
        title: `Als Favorit ${
          !props.rawAudioItem.isFavorite ? "hinzugefügt" : "entfernt"
        }`,
        icon: "checkmark"
      });
    };

    const favoriteTooltip = computed(() => {
      return isFavorite.value
        ? "Als Favorit entfernen"
        : "Zu Favoriten hinzufügen";
    });

    const starSwitch = computed(() => {
      return isFavorite.value ? "starFilled" : "star";
    });

    const { isAdminOrEditor } = useGetUserRole();

    const onOptionSelected = ({ value }, downloadAudio) => {
      switch (value) {
        case "download":
          downloadAudio();
          break;
        case "share":
          share();
          break;
        default:
          break;
      }
    };

    const showRemoveFromPlaylistDialog = ref(false);

    const onAudioItemSelected = ({ value }) => {
      switch (value) {
        case "createPlaylist":
          createPlaylist();
          break;
        case "edit":
          editAudioItem();
          break;
        case "delete":
          deleteAudioItem();
          break;
        case "remove":
          ManagePlaylistDialogEventBus.$emit("removeItemsFromPlaylist", {
            audioItems: [props.audioItem]
          });
          break;
        default:
          break;
      }
    };

    const isPlaying = computed(() => {
      const { isPlaying: playingState, audioId } = store.state.audioPlayer;

      return audioId === audioContent.value.residref ? playingState : false;
    });

    const transcriptHighlightStyles = computed(() => ({
      /* magic nunber: 2 lines */
      maxHeight: `calc(1em * 1.5${!isDesktop.value ? " * 2" : ""})`
    }));

    const { isExternal } = useGuid(props.audioItem.guid);

    const handlePersonClick = personName => {
      emit("personClick", personName);
      useMatomoEvent({
        category: "List View",
        action: "Click Speaker",
        name: "Speaker"
      });
    };

    const trackKeywordsEvent = () => {
      useMatomoEvent({
        category: "List View",
        action: "Click Keyword",
        name: "Keywords"
      });
    };

    return {
      audioContent,
      audioIcon,
      content_created,
      downloadIsLoading,
      favoritesMatomo,
      guid,
      headline,
      hasPlaylistId,
      highlights,
      isLeftNavOpen,
      isMobile,
      isPlaying,
      keywords,
      onOptionSelected,
      onAudioItemSelected,
      persons,
      screen,
      share,
      toggleFavorite,
      transcriptHighlightStyles,
      type,
      updateSearch,
      waveform,
      handlePersonClick,
      trackKeywordsEvent,
      isExternal,
      isAdminOrEditor,
      showRemoveFromPlaylistDialog,
      isFavorite,
      starSwitch,
      favoriteTooltip,
      showFavoriteOption
    };
  }
});
