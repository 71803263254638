import store from "@/store";
import useExpandedSearch from "@/composition/useExpandedSearch";

export default function updateSearch(keyword: string): void {
  const { executeSearch } = useExpandedSearch();

  // Encapsulate multi-word keywords with double quotes
  const formattedKeyword = keyword.match(/\s/) ? `"${keyword}"` : keyword;
  store.commit("filterRules/setFullTextSearch", formattedKeyword);

  const currentFulltextRules = store.state.filterRules.filterRules.filter(
    ({ type }) => type === "FILTER_FULLTEXT_FIELDS"
  );

  store.dispatch("filterRules/replaceFilterRules", {
    removedFilterRules: currentFulltextRules,
    addedFilterRules: [
      {
        type: "FILTER_FULLTEXT_FIELDS",
        values: [keyword],
        fields: ["FIELD_KEYWORD"]
      }
    ]
  });

  executeSearch();
}
