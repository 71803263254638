













import { defineComponent } from "@/composition-api";
import UiBanner from "@/components/ui/UiBanner.vue";
import { appBannerState } from "@/composition/useAppBanner";

export default defineComponent({
  components: { UiBanner },
  setup() {
    return { appBannerState };
  }
});
