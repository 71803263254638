


























































import { defineComponent } from "@/composition-api";
import UiIconPlay from "@/assets/vectors/icons/play.svg?inline";
import UiIconDownload from "@/assets/vectors/icons/download.svg?inline";
import UiIconShare from "@/assets/vectors/icons/share.svg?inline";
import UiIconMore from "@/assets/vectors/icons/more.svg?inline";

export default defineComponent({
  name: "AHLoadingSkeletonItem",
  components: {
    UiIconPlay,
    UiIconDownload,
    UiIconShare,
    UiIconMore
  }
});
