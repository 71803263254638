




























import { defineComponent } from "@/composition-api";
import UiIconCheckmark from "@/assets/vectors/icons/checkmark.svg?inline";
import UiIconColorPalette from "@/assets/vectors/icons/color-palette.svg?inline";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";

export default defineComponent({
  components: {
    UiIconCheckmark,
    UiIconColorPalette,
    UiButtonIcon
  },
  props: {
    type: {
      type: String,
      validator: (val: string) => ["palette", "diamond"].includes(val)
    },
    value: {
      type: String,
      required: true
    }
  }
});
