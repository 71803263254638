






















import { computed, defineComponent, ref } from "@/composition-api";
import store from "@/store";
import { format } from "date-fns";
import UiImageCardDetails from "../ui/UiImageCardDetails.vue";
import UiBadge from "@/components/ui/UiBadge.vue";
import useGetUserRole from "@/composition/useGetUserRole";

export default defineComponent({
  components: {
    UiImageCardDetails,
    UiBadge
  },
  props: {
    curatedFilterId: {
      type: String,
      required: true,
      default: undefined
    }
  },

  setup(props) {
    const filterId = computed(() => props.curatedFilterId);
    const curatedFilterId = computed(() => {
      return filterId.value;
    });
    const curatedSearchImageURL = computed(
      () => store.state.stageconfig.config.curatedSearchImageURL
    );

    const curatedFilter = computed(() =>
      store.getters["filters/getFilterById"](curatedFilterId.value)
    );

    const curatedFilterName = computed(() => {
      return curatedFilter.value?.name;
    });
    const curatedFilterDescription = ref(
      curatedFilter.value?.description as string
    );

    const isCuratedSearchActive = ref(
      curatedFilter.value?.isCuratedSearchActive as boolean
    );

    const curatedFilterImg = ref(
      curatedFilter.value?.imageIdentifier as string
    );
    const updatedAtTime = curatedFilter.value?.updatedAt
      ? ref(format(new Date(curatedFilter.value.updatedAt), "dd.MM.yyyy HH:mm"))
      : ref(undefined);

    const isCuratedSearch = ref(curatedFilter.value?.curatedSearch as boolean);

    const curatedImgUrl = computed(() => {
      return `${curatedSearchImageURL.value}/${curatedFilterImg.value}`;
    });

    const { isAdmin, isEditor } = useGetUserRole();

    const showBadge = isPublished => {
      if (isPublished && (isEditor.value || isAdmin.value)) {
        return "veröffentlicht";
      } else if (!isPublished && (isEditor.value || isAdmin.value)) {
        return "entwurf";
      }
    };

    const onOpenCuratedSearchEditDialog = () => {
      store.commit("filters/setShowCuratedSearchEditDialog", true);
    };

    const { isAdminOrEditor } = useGetUserRole();

    return {
      curatedFilterId,
      curatedFilterName,
      curatedFilterDescription,
      curatedImgUrl,
      updatedAtTime,
      isCuratedSearch,
      isCuratedSearchActive,
      showBadge,
      onOpenCuratedSearchEditDialog,
      isAdminOrEditor
    };
  }
});
