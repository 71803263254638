

















































import { computed, defineComponent, ref } from "@/composition-api";
import useFetchCuratedSearch from "@/composition/useFetchCuratedSearch";
import useGetUserRole from "@/composition/useGetUserRole";
import ChevronDownIcon from "@/assets/vectors/icons/chevron-down.svg?inline";
import UiImageCard from "../ui/UiImageCard.vue";
import UiButton from "../ui/UiButton.vue";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useFetchPlaylists from "@/composition/useFetchPlaylists";
import { compareDesc } from "date-fns";

export default defineComponent({
  components: {
    UiImageCard,
    ChevronDownIcon,
    UiButton
  },

  setup() {
    const isCuratedSearchItemsVisible = ref(true);
    const { onCardClick: onCuratedSearchCardClick } = useFetchCuratedSearch();
    const {
      publicPlaylist,
      onCardClick: onPlaylistCardClick
    } = useFetchPlaylists();
    const { curatedSearches, orderCuratedSearches } = useFetchCuratedSearch();

    const mixedCuratedAndPlaylists = computed(() => {
      const playlists = publicPlaylist.value.map(playlist => ({
        ...playlist,
        isCuratedSearchActive: playlist.isPlaylistActive,
        type: "playlist",
        onCardClick: onPlaylistCardClick
      }));

      const curatedSearchesPreview = computed(() => {
        return orderCuratedSearches(curatedSearches).map(playlist => ({
          ...playlist,
          type: "curatedSearch",
          onCardClick: onCuratedSearchCardClick
        }));
      });

      return curatedSearchesPreview.value
        .concat(playlists)
        .sort((a, b) => compareDesc(a.updatedAt, b.updatedAt))
        .slice(0, 3);
    });

    const transformChevronIcon = computed(
      () =>
        `duration-200 ease-linear transform transform-transition ${
          isCuratedSearchItemsVisible.value ? "rotate-180" : ""
        }`
    );
    const { isAdmin, isEditor } = useGetUserRole();

    const isAdminView = computed(() => isAdmin.value || isEditor.value);

    const showBadge = isPublished => {
      if (isPublished && isAdminView.value) {
        return "veröffentlicht";
      } else if (!isPublished && isAdminView.value) {
        return "entwurf";
      }
    };

    return {
      isCuratedSearchItemsVisible,
      transformChevronIcon,
      showBadge,
      useMatomoEvent,
      mixedCuratedAndPlaylists
    };
  }
});
