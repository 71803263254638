import api, { setSentryTransactionId } from "@/api";
import useAuthentication from "@/composition/useAuthentication";
import store from "@/store";

export default () => {
  const authJWT = store.state.auth.token;
  const { validateAndRetrieveToken } = useAuthentication();

  const handleImageIdentifierUpload = async (imageFile, imageName) => {
    // upload ImageIdentifier
    if (imageName.value != "") {
      const payload = new FormData();
      payload.append("file", imageFile.value);

      await api
        .post({
          url: `resources/${imageName.value}`,
          payload,
          authJWT: validateAndRetrieveToken(),
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Transaction-ID": setSentryTransactionId()
          }
        })
        .catch(error => {
          console.log("Image upload failed");
          console.log(error.response);
        });
    }
  };

  const handleImageIdentifierDelete = imageIdentifier => {
    // delete imageIdentifier
    api
      .delete({
        url: `resources/${imageIdentifier.value}`,
        authJWT,
        headers: {
          "X-Transaction-ID": setSentryTransactionId()
        }
      })
      .catch(error => {
        console.log("error delete");
        console.log(error.response);
      });
  };

  return { handleImageIdentifierUpload, handleImageIdentifierDelete };
};
