import { watch, computed } from "@/composition-api";
import deepEqual from "fast-deep-equal";
import store from "@/store";
import {
  applyAudioServiceShortcutFilterRules,
  applySavedSearchFilterRules,
  removeSavedSearchFilterRules
} from "@/components/nav/navigation.composition";
import useAudioTypeQuery from "./useAudioTypeQuery";
import useFetchAudioItems from "./useFetchAudioItems";

export default () => {
  const { sort, fetchAudioitems } = useFetchAudioItems();
  const { audioType } = useAudioTypeQuery();
  const filterId = computed(() => store.state.route.query.filterId);
  const playlistId = computed(() => store.state.route.query.playlistId);
  const isExpandedSearchOpen = computed(
    () => store.state.expandedSearch.isExpandedSearchOpen
  );

  // Initial fetching of audio items
  if (!playlistId.value)
    fetchAudioitems({
      hasNewFilterRules: true,
      hasNewSort: true
    });

  watch(
    sort,
    (newValue, oldValue) => {
      if (!deepEqual(newValue, oldValue) && !isExpandedSearchOpen.value) {
        fetchAudioitems({
          hasNewFilterRules: false,
          hasNewSort: true
        });
      }
    },
    {
      deep: true
    }
  );

  watch(
    audioType,
    (newValue, oldValue) => {
      const isAudioTypeShortcutChanged = !deepEqual(newValue, oldValue);

      if (isAudioTypeShortcutChanged) {
        applyAudioServiceShortcutFilterRules(audioType.value);

        fetchAudioitems({
          hasNewFilterRules: true,
          hasNewSort: true
        });
      }
    },
    { immediate: true }
  );

  watch(
    filterId,
    (newValue, oldValue) => {
      const isSavedSearchChanged = !deepEqual(newValue, oldValue);

      if (isSavedSearchChanged && filterId.value) {
        applySavedSearchFilterRules(String(filterId.value));

        fetchAudioitems({
          hasNewFilterRules: true,
          hasNewSort: true
        });
      }

      if (oldValue && !newValue) {
        removeSavedSearchFilterRules(String(oldValue));
        applyAudioServiceShortcutFilterRules(audioType.value);

        fetchAudioitems({
          hasNewFilterRules: true,
          hasNewSort: true
        });
      }
    },
    { immediate: true }
  );
};
