















import { defineComponent } from "@/composition-api";
import SearchIcon from "@/assets/vectors/icons/search.svg?inline";

export default defineComponent({
  components: {
    SearchIcon
  }
});
