




































import { computed, defineComponent, ref } from "@/composition-api";
import UiIconCheckmark from "@/assets/vectors/icons/checkmark.svg?inline";
import NativeColorInput from "./NativeColorInput.vue";

const COLORS = [
  "#e53935",
  "#f5138d",
  "#8e24aa",
  "#00acc1",
  "#00897b",
  "#30df7b",
  "#f9f500",
  "#ffb300"
];

export default defineComponent({
  components: {
    UiIconCheckmark,
    NativeColorInput
  },
  props: {
    value: {
      type: String,
      // Assign random default color
      default: COLORS[Math.floor(Math.random() * COLORS.length)]
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    emit("input", props.value);

    const showColorpicker = ref(false);
    const colors = COLORS;
    const nativePicker = ref();

    const isCustomColor = computed(() => {
      return props.value && !colors.some(color => color === props.value);
    });

    const updateCustomColor = ({ target }) => {
      emit("input", target.value);
    };

    const onDiamondClick = (color: string) => {
      showColorpicker.value = false;
      emit("input", color);
    };

    const onNativePickerClick = () => {
      if (!props.isMobile) nativePicker.value?.click();
    };

    return {
      showColorpicker,
      colors,
      isCustomColor,
      updateCustomColor,
      nativePicker,
      onDiamondClick,
      onNativePickerClick
    };
  }
});
