import useMatomoEvent from "@/composition/useMatomoEvent";
import store from "@/store";
import { computed } from "@vue/composition-api";

export default () => {
  /**
   * Toggle state for save search dialog
   */
  const showSaveSearchDialog = computed({
    get() {
      return store.state.filters.showSaveSearchDialog;
    },
    set(value) {
      store.commit("filters/setShowSaveSearchDialog", value);
      useMatomoEvent({
        category: "List View",
        action: "Click Suche speichern",
        name: "Save Search"
      });
    }
  });

  return { showSaveSearchDialog };
};
