var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-page-layout',[_c('template',{slot:"h1"},[_vm._v("Einstellungen")]),_c('template',{slot:"h2"},[_vm._v("Gespeicherte Suchen")]),_c('template',{slot:"content"},[(_vm.savedSearches.length)?_vm._l((_vm.savedSearches),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var iconColor = ref.iconColor;
var to = ref.to;
return _c('ui-card',{key:("savedSearch-settings-" + index),staticClass:"flex flex-row items-center py-4 pl-4 pr-2 border-b",attrs:{"to":to}},[_c('saved-search-label',_vm._b({staticClass:"flex-grow"},'saved-search-label',{ icon: icon, iconColor: iconColor, label: label },false)),_c('router-link',{staticClass:"px-4 text-sm font-medium text-blue hover:text-blue-dark",attrs:{"append":"","to":to}},[_vm._v("Bearbeiten")])],1)}):[_c('ui-card',{staticClass:"flex flex-col items-center justify-center px-8 py-12 text-center border-b"},[_c('search-icon',{staticClass:"w-12 h-12 text-gray-900"}),_c('span',{staticClass:"mt-4 font-medium text-gray-900"},[_vm._v("Sie haben noch keine Suchen gespeichert")]),_c('span',{staticClass:"mt-2 text-sm text-gray-800"},[_vm._v(" Speichern Sie Suchen mit Klick auf “Suche speichern” auf der Ergebnisseite. Dann können Sie sie hier anpassen, Benachrichtigungen verwalten und mehr. ")])],1)],(_vm.savedSearches.length)?[_c('h2',{staticClass:"mt-12 mb-4 font-medium text-gray-900"},[_vm._v("Benachrichtigungen")]),_c('span',{staticClass:"flex mb-5 text-sm text-gray-800 lg:w-full sm:w-full"},[_vm._v(" Bestimmen Sie hier, wie wir Ihnen neue Treffer Ihrer gespeicherten Suchen zustellen sollen. ")]),_c('ui-card',{staticClass:"flex flex-wrap border-b"},[_c('p',{staticClass:"flex w-1/2 px-4 py-4 border-r"}),_c('p',{staticClass:"inline w-1/2 py-4 text-sm font-semibold text-center text-gray-900"},[_vm._v(" E-Mail ")])]),_vm._l((_vm.savedSearches),function(savedSearch,index){return _c('ui-card',{key:("savedSearch-settings-notification-" + index),staticClass:"flex flex-wrap border-b"},[_c('div',{staticClass:"w-1/2 px-4 py-4 border-r"},[_c('saved-search-label',_vm._b({},'saved-search-label',{
              icon: savedSearch.icon,
              iconColor: savedSearch.iconColor,
              label: savedSearch.label
            },false))],1),_c('div',{staticClass:"inline w-1/2 pt-4 text-center"},[_c('ui-toggle',{attrs:{"value":savedSearch.isNotificationEnabled},on:{"input":function($event){return _vm.updateNotificationEnabled(savedSearch, $event)}}})],1)])})]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }