





























import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import { defineComponent, ref } from "@/composition-api";
import UiButton from "@/components/ui/UiButton.vue";
import { selectedAudioItems, allChecked } from "@/composition/useBulkSelection";

export default defineComponent({
  name: "AHMoreThanFiftyDialog",
  components: { UiButton, UiDialog, UiIconClose },
  props: {
    moreThanFifty: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const visible = ref(props.moreThanFifty);

    const limitToFifty = selectedAudioItems.value.slice(0, 50);

    const hideDialog = () => {
      visible.value = false;
    };
    return {
      hideDialog,
      visible,
      selectedAudioItems,
      allChecked,
      limitToFifty
    };
  }
});
