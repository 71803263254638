/* eslint-disable-next-line vue/no-unused-vars */
<template>
  <div>
    <ui-menu size="medium" :options="options" @optionSelect="handleOptionClick">
      <button
        slot="activator"
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="flex items-center justify-center text-gray-900 w-8 h-8 hover:bg-gray-300 focus:bg-gray-300  bg-opacity-50 focus:outline-none rounded-3xl"
      >
        <ui-icon-more class="w-6 h-6 fill-current" />
      </button>
    </ui-menu>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@/composition-api";
import UiIconMore from "@/assets/vectors/icons/more.svg?inline";
import UiMenu from "@/components/ui/UiMenu";
import AHAudioEdit from "@/components/AHAudioEdit/AHAudioEdit";
import AHAudioDelete from "@/components/AHAudioDelete/AHAudioDelete";

export default defineComponent({
  components: { AHAudioDelete, AHAudioEdit, UiIconMore, UiMenu },
  props: {
    guid: String,
    headline: String,
    hasPlaylistId: Boolean
  },
  setup(props, { emit }) {
    const guid = computed(() => props.guid);
    const headline = computed(() => props.headline);
    const hasPlaylistId = computed(() => props.hasPlaylistId);

    const options = ref([
      {
        label: "Zu Playlist hinzufügen",
        value: "createPlaylist"
      },
      {
        label: "Bearbeiten",
        value: "edit"
      },
      {
        label: "Löschen",
        value: "delete"
      }
    ]);

    if (hasPlaylistId.value) {
      options.value = [
        {
          label: "Aus Playlist entfernen",
          value: "remove"
        },
        {
          label: "Bearbeiten",
          value: "edit"
        }
      ];
    }

    const handleOptionClick = option => {
      emit("option-select", {
        value: option.value,
        guid: guid.value,
        headline: headline.value
      });
    };

    return { options, open, close, handleOptionClick };
  }
});
</script>
<style></style>
