var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full pt-2 pb-3 pl-3 pr-4 mb-px transition bg-white border border-transparent shadow md:pt-3 hover:shadow-md hover:border-gray-500",class:_vm.isPlaying ? 'shadow-md border-gray-500' : ''},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-none"},[_c('div',{staticClass:"w-12 mr-2 md:w-8 md:mr-3"},[_c('a-h-play-button',{attrs:{"audio-content":_vm.audioContent,"author":_vm.persons.join(', '),"guid":_vm.guid,"title":_vm.headline,"waveform":_vm.waveform,"is-favorite":_vm.isFavorite,"small":""}})],1)]),_c('div',{staticClass:"flex-auto min-w-0"},[_c('div',{staticClass:"md:flex md:items-center md:-mx-2"},[_c('div',{staticClass:"md:flex-auto md:min-w-0 md:px-2"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-1 md:mb-1"},[_c('ul',{staticClass:"text-xs font-medium leading-normal text-gray-900",attrs:{"data-testid":"persons"}},_vm._l((_vm.persons),function(person,index){return _c('li',{key:person,staticClass:"inline-block"},[_c('a',{staticClass:"hover:underline",on:{"click":function($event){$event.preventDefault();return _vm.handlePersonClick(person)}}},[_vm._v(_vm._s(person))]),(index < _vm.persons.length - 1)?_c('span',{domProps:{"innerHTML":_vm._s(',&nbsp;')}}):_vm._e()])}),0)]),_c('div',{staticClass:"flex-initial md:hidden"},[_c('div',{staticClass:"-mr-2"},[_c('a-h-audio-download',{attrs:{"audio-content":_vm.audioContent,"guid":_vm.guid,"headline":_vm.headline,"origin":"listview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var downloadAudio = ref.downloadAudio;
return [_c('more-button',{staticClass:"rounded-full hover:bg-gray-400 focus:bg-gray-400 focus:outline-none",on:{"option-select":function($event){return _vm.onOptionSelected($event, downloadAudio)}}})]}}])})],1)])]),_c('div',{staticClass:"mb-2 md:mb-0",attrs:{"data-testid":"headline","title":_vm.headline}},[_c('div',{staticClass:"text-sm font-medium leading-normal text-gray-900 md:truncate hover:underline"},[(_vm.highlights.headline)?_vm._l((_vm.highlights.headline),function(value,name,index){return _c('ui-highlight',{key:("headline-" + index),staticClass:"md:truncate",attrs:{"highlight":value,"tag":"h2"}},[(_vm.isExternal)?_c('ui-badge',{staticClass:"ml-2 text-gray-700",attrs:{"bg-color":"bg-gray-200"}},[_vm._v("Partner")]):_vm._e()],1)}):_c('h2',{staticClass:"md:truncate"},[_vm._v(" "+_vm._s(_vm.headline)+" "),(_vm.isExternal)?_c('ui-badge',{staticClass:"ml-2 text-gray-700",attrs:{"bg-color":"bg-gray-200"}},[_vm._v("Partner")]):_vm._e()],1)],2)])]),_c('div',{staticClass:"md:flex-none md:px-2"},[_c('div',{staticClass:"text-gray-700"},[_c('div',{staticClass:"flex items-center justify-between -mx-2"},[_c('div',{staticClass:"flex-none px-2"},[_c('div',{staticClass:"flex w-20"},[_c(_vm.audioIcon,{tag:"component",staticClass:"flex-shrink-0 w-4 h-4 fill-current"}),_c('span',{staticClass:"px-2 text-xs whitespace-no-wrap"},[_vm._v(" "+_vm._s(_vm.type)+" ")])],1)]),_c('div',{staticClass:"relative flex-none px-2 overflow-x-hidden KeywordsContainer"},[_c('router-link',{staticClass:"display-contents",attrs:{"to":{
                    name: 'audioitems',
                    params: { viewMode: 'list' },
                    query: _vm.$route.query
                  }}},[_c('keywords',{staticClass:"hidden w-64 xl:flex",attrs:{"keywords":_vm.keywords},on:{"keywordClick":_vm.updateSearch},nativeOn:{"click":function($event){return _vm.trackKeywordsEvent($event)}}})],1)],1),_c('div',{staticClass:"flex-none px-2"},[_c('div',{staticClass:"w-24 text-xs whitespace-no-wrap"},[_vm._v(" "+_vm._s(_vm.content_created)+" ")])]),(_vm.showFavoriteOption)?_c('ui-tooltip',{staticClass:"md:pl-4 md:text-gray-900",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('ui-button-icon',{nativeOn:{"click":[function($event){$event.preventDefault();return _vm.toggleFavorite($event)},function($event){return _vm.favoritesMatomo($event)}]}},[_c('ui-icon',{class:_vm.isFavorite ? 'text-blue' : '',attrs:{"name":_vm.starSwitch}})],1)]},proxy:true}],null,false,4165940397)},[_vm._v(" "+_vm._s(_vm.favoriteTooltip)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"md:flex-none md:px-2"},[_c('div',{staticClass:"hidden md:flex "},[_c('a-h-audio-download',{attrs:{"audio-content":_vm.audioContent,"guid":_vm.guid,"headline":_vm.headline,"origin":"listview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var downloadAudio = ref.downloadAudio;
                  var downloadIsLoading = ref.downloadIsLoading;
return [_c('ui-tooltip',{staticClass:"mx-2",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('ui-button-icon',{nativeOn:{"click":function($event){$event.preventDefault();return downloadAudio($event)}}},[(downloadIsLoading)?_c('ui-icon-spinner',{staticClass:"m-1 text-gray-900 fill-current animate-spin icon-small"}):_c('ui-icon-download',{staticClass:"w-6 h-6 fill-current"})],1)]},proxy:true}],null,true)},[_vm._v(" Herunterladen ")])]}}])}),_c('ui-tooltip',{staticClass:"mx-2",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('ui-button-icon',{nativeOn:{"click":function($event){$event.preventDefault();return _vm.share($event)}}},[_c('ui-icon',{attrs:{"name":"share","size":"medium"}})],1)]},proxy:true}])},[_vm._v(" Teilen ")]),(_vm.isAdminOrEditor)?_c('a-h-audio-item-context-menu',{staticClass:"rounded-full focus:outline-none",attrs:{"guid":_vm.guid,"headline":_vm.headline,"has-playlist-id":_vm.hasPlaylistId},on:{"option-select":_vm.onAudioItemSelected}}):_vm._e()],1)])]),(_vm.highlights.snippet && _vm.highlights.snippet.length)?_c('div',{staticClass:"mt-2"},_vm._l((_vm.highlights.snippet),function(value,index){return _c('ui-highlight',{key:("transcript-" + index),staticClass:"overflow-y-hidden text-xs text-gray-900",style:(_vm.transcriptHighlightStyles),attrs:{"highlight":value,"data-testid":"highlights"}})}),1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }