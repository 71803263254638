var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"contentTemplateRef",staticClass:"h-full"},[_c('a-h-app-banner'),(
      !_vm.isFavorites &&
        _vm.isCuratedSearchPreview &&
        _vm.isCuratedSearchAndPlaylistsEnabled
    )?_c('a-h-curated-search-preview'):_vm._e(),(_vm.isCuratedSearchItemDetail)?_c('a-h-curated-search-item-detail',{attrs:{"curated-filter-id":_vm.curatedFilterId}}):_vm._e(),(_vm.isPlaylistItemDetail)?_c('a-h-playlist-item-detail',{attrs:{"playlist-id":_vm.playlistId}}):_vm._e(),(_vm.isFavorites && _vm.audioitems.length > 0)?_c('a-h-favorites'):_vm._e(),_c('a-h-feed-actions',_vm._b({},'a-h-feed-actions',{
      isCreateCuratedSearch: _vm.isCreateCuratedSearch,
      isUpdateCuratedSearch: _vm.isUpdateCuratedSearch,
      isSavedSearchApplied: _vm.isSavedSearchApplied
    },false)),(
      _vm.isCuratedSearchAndPlaylistsEnabled &&
        !_vm.isFavorites &&
        !_vm.hasOnlyAudioTypeFilterRules &&
        !_vm.$route.query.playlistId &&
        _vm.hasAppliedFilterRules &&
        _vm.searchPublicLists.length &&
        !_vm.isLoading
    )?_c('a-h-public-lists-search',{attrs:{"has-audioitems":!!_vm.audioitems.length,"public-lists":_vm.searchPublicLists}}):_vm._e(),(_vm.isLoading && !_vm.shouldLoadMore)?_c('div',{staticClass:"md:pl-4 pt-16 pb-24"},_vm._l((_vm.audioitems.length + 50),function(n){return _c('a-h-loading-skeleton-item',{key:n})}),1):(!_vm.audioitems.length)?_c(_vm.noSearchResultsVariant,{tag:"component"}):_c('div',{staticClass:"pb-24"},_vm._l((_vm.audioitems),function(audioitem,index){return _c('router-link',{key:("audioitem-" + index),staticClass:"AudioItem block focus-visible:outline-blue",attrs:{"to":{
        name: 'detail',
        params: { viewMode: 'list', guid: audioitem.guid },
        query: _vm.$route.query
      }},nativeOn:{"click":function($event){return _vm.useMatomoEvent({
          category: 'Detail View',
          action: 'Click List View Item',
          name: 'Open Detail View'
        })}}},[_c('ui-transition-opacity',{attrs:{"enter-duration":"1000","leave-duration":"800"},on:{"after-enter":_vm.resetPolledItem}},[_c('div',{staticClass:"relative ml-0 md:ml-8 xl:ml-4"},[(!audioitem.polled || _vm.showPolled)?_c('a-h-audioitem',{attrs:{"audio-item":_vm.audioItemById(audioitem.guid),"raw-audio-item":audioitem},on:{"personClick":_vm.handlePersonClick}}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"absolute align-middle flex flex-col justify-center pr-4",staticStyle:{"height":"72px","top":"0","left":"-36px","cursor":"default !important"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('ui-check-box',{staticClass:"AudioItem-hover:visible",class:_vm.showBulkSelectionMenu && _vm.anySelected ? '' : 'invisible',attrs:{"checked":_vm.isAudioitemSelected(audioitem)},on:{"change":function (checked) { return (audioitem.selected = checked); }},nativeOn:{"click":function($event){return (function () {
                  _vm.showBulkSelectionMenu = true;
                  _vm.toggleItemSelection(audioitem);
                })($event)}}})],1):_vm._e()],1)])],1)}),1),(_vm.shouldLoadMore)?_c('div',{staticClass:"flex justify-center mb-16"},[_c('ui-spinner')],1):_vm._e(),_c('ui-transition-opacity',[(_vm.getActiveAudioItem !== null)?_c('a-h-audioitem-detail',{attrs:{"audio-item":_vm.getActiveAudioItem,"raw-audio-item":_vm.activeAudioItem},on:{"personClickDetail":function (personName) {
          _vm.handlePersonClick(personName);
          _vm.closeDetailView();
        }}}):_vm._e()],1),_c('ui-transition-opacity',[(_vm.showSaveSearchDialog)?_c('a-h-save-search-dialog',{on:{"close":function($event){_vm.showSaveSearchDialog = false}}}):_vm._e()],1),_c('ui-transition-opacity',[(_vm.showCuratedSearchDialog)?_c('a-h-curated-search-dialog',{on:{"close":function($event){_vm.showCuratedSearchDialog = false}}}):_vm._e()],1),_c('ui-transition-opacity',[(_vm.showCuratedSearchEditDialog)?_c('a-h-curated-search-edit-dialog',{attrs:{"curated-filter-id":_vm.curatedFilterId},on:{"close":function($event){_vm.showCuratedSearchEditDialog = false}}}):_vm._e()],1),_c('a-h-audio-edit'),_c('a-h-audio-delete'),_c('a-h-add-to-playlist-dialog'),_c('a-h-remove-from-playlist-dialog',{attrs:{"audio-items":_vm.removeFromPlaylistItems,"show":_vm.showRemoveFromPlaylistDialog},on:{"close":function($event){_vm.showRemoveFromPlaylistDialog = false;
      _vm.removeFromPlaylistItems = [];}}}),(_vm.showCreatePlaylistDialog)?_c('a-h-create-playlist-dialog'):_vm._e(),(_vm.moreThanFiftySelected)?_c('a-h-more-than-fifty-dialog',{attrs:{"more-than-fifty":_vm.moreThanFiftySelected}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }