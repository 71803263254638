<template>
  <ui-menu :options="options" @optionSelect="handleOptionClick">
    <button
      slot="activator"
      type="button"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      class="flex items-center justify-center w-8 h-8 hover:bg-gray-400 focus:bg-gray-400 focus:outline-none rounded-3xl"
      @click.prevent=""
    >
      <ui-icon-more class="w-6 h-6 fill-current" />
    </button>
  </ui-menu>
</template>

<script>
import { defineComponent, ref } from "@/composition-api";
import { mixin as clickaway } from "vue-clickaway";
import UiMenu from "@/components/ui/UiMenu";
import UiIconMore from "@/assets/vectors/icons/more.svg?inline";

export default defineComponent({
  components: { UiMenu, UiIconMore },
  mixins: [clickaway],
  setup(props, { emit }) {
    const options = ref([
      {
        label: "Download",
        value: "download"
      },
      {
        label: "Teilen",
        value: "share"
      }
    ]);

    const handleOptionClick = option => {
      emit("option-select", option);
    };

    return { options, handleOptionClick };
  }
});
</script>
