import { computed } from "@/composition-api";
import store from "@/store";

export default () => {
  const filterNames = computed(() => store.state.filters);
  const currentFilterId = computed(() => store.state.route.params.filterId);
  const currentFilter = computed(() =>
    store.getters["filters/getFilterById"](currentFilterId.value)
  );

  const isFilterNameValid = (name: string) => {
    const existingFilters = filterNames.value.filters;

    if (existingFilters.find(filterItem => filterItem.name === name)) {
      if (
        currentFilter.value !== undefined &&
        currentFilter.value.name === name
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return { isFilterNameValid };
};
