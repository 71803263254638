

















































































import { defineComponent, computed } from "@/composition-api";
import SavedSearchLabel from "@/components/nav/SavedSearchLabel.vue";
import store from "@/store";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiPageLayout from "@/components/ui/UiPageLayout.vue";
import UiCard from "@/components/ui/UiCard.vue";
import SearchIcon from "@/assets/vectors/icons/search.svg?inline";

export default defineComponent({
  components: { SavedSearchLabel, SearchIcon, UiToggle, UiPageLayout, UiCard },
  setup() {
    const savedSearches = computed(() => {
      return store.state.filters.filters
        .filter(
          ({ isVisible, curatedSearch }) => isVisible && curatedSearch === false
        )
        .map(({ name, color, id, isNotificationEnabled }) => ({
          label: name,
          icon: "DiamondIcon",
          iconColor: color,
          to: id,
          id,
          isNotificationEnabled
        }));
    });

    const updateNotificationEnabled = (savedSearch, isNotificationEnabled) => {
      const authJWT = store.state.auth.token;

      store.dispatch("filters/updateFilterIsNotificationEnabled", {
        authJWT,
        isNotificationEnabled,
        id: savedSearch.id
      });
    };

    return {
      savedSearches,
      updateNotificationEnabled
    };
  }
});
