









import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    bgColor: {
      type: String,
      required: true,
      default: "bg-green"
    }
  }
});
