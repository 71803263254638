<template>
  <div class="flex-auto flex-grow-0 flex-shrink-0 space-y-1">
    <ui-menu
      :options="optionsWithSelected"
      selectable
      @optionSelect="selectOption"
      @open="buttonIsActive = true"
      @close="buttonIsActive = false"
    >
      <span slot="activator" class="w-full rounded-3xl">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="relative flex flex-row py-1 pl-3 pr-5 text-left transition duration-150 ease-in-out rounded-3xl hover:bg-gray-200 focus-visible:outline-blue focus:outline-none focus:bg-gray-300 sm:text-sm sm:leading-5"
          :class="[
            buttonIsActive ? 'bg-gray-200' : '',
            !isMobile ? 'w-full' : ''
          ]"
        >
          <label
            v-if="!isMobile"
            class="text-xs font-semibold text-gray-800 cursor-pointer"
            >Sortieren nach:</label
          >
          <span
            class="ml-0 mr-3 text-xs text-gray-800 truncate lg:ml-2 xl:ml-2"
          >
            {{ selected.label }}
          </span>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
          >
            <chevron-down-icon class="w-4 h-4 fill-current" />
          </span>
        </button>
      </span>
    </ui-menu>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@/composition-api";
import { mixin as clickaway } from "vue-clickaway";
import equal from "fast-deep-equal";
import UiMenu from "@/components/ui/UiMenu";
import useSortOptions from "@/composition/useSortOptions";
import useBreakpoints from "@/composition/useBreakpoints";
import ChevronDownIcon from "@/assets/vectors/icons/chevron-down.svg?inline";

export default defineComponent({
  mixins: [clickaway],
  components: { ChevronDownIcon, UiMenu },
  setup() {
    const { options, selected } = useSortOptions();
    const { isMobile } = useBreakpoints();

    const buttonIsActive = ref(false);

    const optionsWithSelected = computed(() => {
      const { field, direction, label } = selected.value;
      return options.value.map(item => {
        return {
          ...item,
          selected: equal(item, { field, direction, label })
        };
      });
    });

    const selectOption = option => {
      selected.value = option;
    };

    return {
      buttonIsActive,
      close,
      isMobile,
      optionsWithSelected,
      open,
      options,
      selectOption,
      selected
    };
  }
});
</script>
