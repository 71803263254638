







































import { defineComponent } from "@/composition-api";
import UiBadge from "@/components/ui/UiBadge.vue";

export default defineComponent({
  components: {
    UiBadge
  },
  props: {
    imgUrl: {
      type: String,
      required: false,
      default: ""
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    badge: {
      type: String,
      required: false,
      default: ""
    },
    layout: {
      type: String,
      default: "image",
      validator(val: string) {
        return ["image", "list"].includes(val);
      }
    },
    isPublicListSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { isLoading: true };
  }
});
