
































































import debounce from "lodash.debounce";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  inject
} from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";
import UiMenu from "@/components/ui/UiMenu.vue";
import store from "@/store";
import AHAddToPlaylistDialogEventBus from "@/components/AHPlaylist/AHAddToPlaylistDialogEventBus";
import AHCreatePlaylistDialog from "@/components/AHPlaylist/AHCreatePlaylistDialog.vue";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiIconPlus from "@/assets/vectors/icons/plus.svg?inline";
import { watch } from "@vue/composition-api";
import { clearBulkSelection } from "@/composition/useBulkSelection";
import { selectedGuids } from "./playlistState";

export default defineComponent({
  components: {
    AHCreatePlaylistDialog,
    UiDialog,
    UiIconClose,
    UiInput,
    UiTextArea,
    UiToggle,
    UiButton,
    UiIconPlus,
    UiErrorMessage,
    UiMenu,
    UiTooltip,
    UiButtonIcon
  },
  setup(_, { emit }) {
    const showDialog = ref(false);

    const eventBus: Vue = inject("eventBus");
    const playlists = computed(() => {
      return store.getters["playlists/getPlaylists"].map(it => {
        return { label: it.name, id: it.id };
      });
    });
    const selectedPlaylistName = ref("");
    const selectedPlaylistId = ref("");
    const guids = ref([]);
    const openCreatePlaylistDialog = () => {
      store.commit("playlists/setShowCreatePlaylistDialog", true);
      selectedGuids.value = guids.value;
    };
    const handleOptionClick = option => {
      emit("option-select", {
        value: option.label
      });
      selectedPlaylistName.value = option.label;
      selectedPlaylistId.value = option.id;
    };
    const showCreatePlaylistDialog = computed(
      () => store.getters["playlists/getShowCreatePlaylistDialog"]
    );

    const closeDialog = () => {
      showDialog.value = false;
      guids.value = [];
    };

    const openDialog = () => {
      showDialog.value = true;
    };
    const selectedPlaylist = computed(() =>
      store.getters["playlists/getPlaylistById"](selectedPlaylistId.value)
    );

    const save = () => {
      const newPlaylistItems = guids.value.filter(guid =>
        selectedPlaylist.value.audioItems?.every(itemGuid => itemGuid !== guid)
      );
      const shouldAddToPlaylist =
        selectedPlaylist.value.audioItems && newPlaylistItems.length;

      if (shouldAddToPlaylist) {
        const authJWT = store.state.auth.token;
        store.dispatch("playlists/addToPlaylist", {
          playlistId: selectedPlaylistId.value,
          audioItems: newPlaylistItems,
          authJWT
        });
        eventBus.$emit("show", {
          title: "Zur Playlist hinzugefügt",
          icon: "checkmark"
        });
        closeDialog();
      } else {
        eventBus.$emit("show", {
          title:
            guids.value.length > 1
              ? `${guids.value.length} Töne sind bereits in der Playlist`
              : "Bereits Teil der Playlist"
        });
        closeDialog();
      }

      clearBulkSelection();
    };

    watch(showCreatePlaylistDialog, newVal => {
      if (showCreatePlaylistDialog && !newVal) {
        showDialog.value = false;
      }
    });

    const setDefaultPlaylist = () => {
      selectedPlaylistName.value =
        playlists.value &&
        playlists.value.length >= 0 &&
        playlists.value[0].label;
      selectedPlaylistId.value =
        playlists.value && playlists.value.length >= 0 && playlists.value[0].id;
    };

    onMounted(() => {
      AHAddToPlaylistDialogEventBus.$on("createPlaylist", ({ guid }) => {
        guids.value = [guid.value];
        openDialog();
        setDefaultPlaylist();
      });

      AHAddToPlaylistDialogEventBus.$on(
        "addAudioItemsToPlaylist",
        debounce(({ audioItems }) => {
          selectedGuids.value = audioItems.map(({ guid }) => guid);
          guids.value = selectedGuids.value;
          openDialog();
          setDefaultPlaylist();
        })
      );
    });

    return {
      playlists,
      selectedPlaylistName,
      showDialog,
      handleOptionClick,
      save,
      openDialog,
      closeDialog,
      openCreatePlaylistDialog,
      guids
    };
  }
});
