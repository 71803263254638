import { computed } from "@/composition-api";
import { format } from "date-fns";
import store from "@/store";

/**
 * Returns the headline string for a given audio item
 * @param {object} audioitem
 * @returns {undefined | string}
 */
export const headline = audioitem => audioitem.headline?.[0];

/**
 * Returns person subjects subjects for a given audioitem
 * @param {object} audioitem
 * @returns {Array<object> | undefined}
 */
export const audioitemPerson = audioitem =>
  audioitem.subject?.filter(({ type }) => !!type.match(/\/person$/));

/**
 *
 * @param {object} audioitem
 * @returns {string}
 */
export const created = audioitem => audioitem.version_created;

/**
 *
 * @param {number|string} timestamp
 * @returns {string}
 */
export const formatDateTime = timestamp =>
  format(new Date(timestamp), "dd.MM.yyyy HH:mm");

/**
 *
 * @param {number} numKeywords
 * @returns {Function}
 */
export const keyword = numKeywords =>
  /**
   * @param {object} audioitem
   * @returns {Array<string> | undefined}
   */
  audioitem => audioitem?.keyword.slice(0, numKeywords);

export default () => {
  const audioitems = computed(() => store.state.audioitems.audioitems);
  const activeAudioItem = computed(
    () => store.state.audioitems.activeAudioItem
  );
  const totalResults = computed(() => store.state.audioitems.totalResults);

  return {
    audioitemPerson,
    audioitems,
    activeAudioItem,
    created,
    headline,
    keyword,
    totalResults
  };
};
