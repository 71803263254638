import { ref, watch, computed } from "@/composition-api";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import router from "@/router";

export default () => {
  const { query } = useRouter(router)();
  const selected = ref();

  const options = ref([
    {
      field: "content_created",
      direction: "desc",
      label: "Neueste zuerst"
    },
    {
      field: "content_created",
      direction: "asc",
      label: "Älteste zuerst"
    },
    {
      field: "duration",
      direction: "asc",
      label: "Kürzeste zuerst"
    },
    {
      field: "duration",
      direction: "desc",
      label: "Längste zuerst"
    }
  ]);

  const findSortOption = ({ field, direction }) =>
    options.value.find(
      option => option.field === field && option.direction === direction
    );

  const sort = computed(() => query.value?.sort);

  watch(
    selected,
    () => {
      const newQuery = {
        ...query.value,
        sort: JSON.stringify({
          field: selected.value.field,
          direction: selected.value.direction
        })
      };

      if (sort.value !== newQuery.sort) {
        router.push({ query: newQuery });
      }
    },
    { deep: true }
  );

  const initialSelection = computed(() => {
    try {
      if (sort.value) {
        const { field, direction } = JSON.parse(sort.value);

        return findSortOption({
          field,
          direction
        });
      }

      return options.value[0];
    } catch (error) {
      console.warn(error);

      return options.value[0];
    }
  });

  selected.value = initialSelection.value;

  // Watch sort query, update selected option if it doesn't match
  watch(sort, () => {
    try {
      const { field, direction } = selected.value;

      const notMatched = sort.value !== JSON.stringify({ field, direction });
      if (sort.value && notMatched) {
        const sortQuery = JSON.parse(sort.value);

        selected.value = findSortOption(sortQuery);
      }
    } catch (error) {
      console.warn(error);

      selected.value = initialSelection.value;
    }
  });

  return { options, selected, query };
};
