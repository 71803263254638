



















import { defineComponent } from "@/composition-api";
import CloseIcon from "@/assets/vectors/icons/close.svg?inline";
import UiButtonIcon from "./UiButtonIcon.vue";

export default defineComponent({
  components: {
    CloseIcon,
    UiButtonIcon
  },
  props: {
    closeable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isVisible: true
    };
  }
});
