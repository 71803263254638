





























































import uuidv1 from "uuid";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  inject,
  watch
} from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";
import store from "@/store";
import router from "@/router";
import useBreakpoints from "@/composition/useBreakpoints";
import Colorpicker from "./parts/Colorpicker.vue";
import useFilterNameValidator from "@/composition/useFilterNameValidator";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiInput,
    UiToggle,
    UiButton,
    UiErrorMessage,
    Colorpicker
  },
  setup(_, { emit }) {
    const name = ref("");
    const color = ref();
    const showInSidebar = ref(true);
    const enableEmailNotification = ref(false);

    const filterRules = computed(() => store.state.filterRules.filterRules);

    const userProfile = computed(() => store.state.user?.profile);

    const savedSearch = computed(() => ({
      id: uuidv1(),
      color: color.value,
      name: name.value,
      owner: userProfile.value?.sub,
      filterRules: filterRules.value,
      isVisible: showInSidebar.value,
      isNotificationEnabled: enableEmailNotification.value,
      isPublic: false
    }));

    const eventBus: Vue = inject("eventBus");

    const save = () => {
      const authJWT = store.state.auth.token;

      store.dispatch("filters/saveFilter", {
        filter: savedSearch.value,
        authJWT
      });

      router.push({
        query: {
          ...router.currentRoute.query,
          filterId: savedSearch.value.id
        }
      });

      eventBus.$emit("show", {
        title: "Suche gespeichert",
        icon: "checkmark"
      });
      emit("close");
    };

    // handle duplicated filter names
    const { isFilterNameValid } = useFilterNameValidator();
    const currentFilterNameInvalid = ref(false);
    watch(name, name => {
      currentFilterNameInvalid.value = isFilterNameValid(name);
    });

    const inputRef = ref();

    onMounted(() => {
      inputRef.value?.$refs?.input?.focus();
    });

    const { isMobile } = useBreakpoints();

    return {
      name,
      color,
      showInSidebar,
      enableEmailNotification,
      savedSearch,
      save,
      inputRef,
      isMobile,
      isFilterNameValid,
      currentFilterNameInvalid
    };
  }
});
