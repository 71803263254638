











































































































import UiButton from "@dpa-id-components/ui-button";
import "@dpa-id-components/ui-button/dist/UiButton.css";
import UiMenu from "@dpa-id-components/ui-menu";
import UiIcon from "@dpa-id-components/ui-icon";
import "@dpa-id-components/ui-icon/dist/UiIcon.css";
import UiList from "@dpa-id-components/ui-list";
import UiListItem from "@dpa-id-components/ui-list-item";
import UiIconButton from "@dpa-id-components/ui-icon-button";
import useAudioitems from "@/composition/useAudioitems";
import useFetchAudioItems from "@/composition/useFetchAudioItems";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useSearchMatches from "@/composition/useSearchMatches";
import AHAudioDownload from "@/components/AHAudioDownload/AHAudioDownload.vue";

import { computed, defineComponent, inject } from "@vue/composition-api";
import {
  showBulkSelectionMenu,
  allChecked,
  toggleAll,
  selectedAudioItems,
  clearBulkSelection
} from "@/composition/useBulkSelection";
import useBreakpoints from "@/composition/useBreakpoints";
import UiSaveSearchButton from "@/components/ui/UiSaveSearchButton.vue";
import AHSearchMatches from "@/components/AHSearchMatches.vue";
import UiCheckBox from "@/components/ui/UiCheckBox.vue";
import AHSortOptions from "@/components/AHSortOptions.vue";
import useCuratedSearchDialog from "../AHCuratedSearch/useCuratedSearchDialog";
import useSaveSearchDialog from "../AHSaveSearchDialog/useSaveSearchDialog";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import router from "@/router";
import useAudioItemOptions from "@/composition/useAudioItemOptions";
import useAuthentication from "@/composition/useAuthentication";
import { PropType } from "vue";
import ManagePlaylistDialogEventBus from "../AHPlaylist/AHAddToPlaylistDialogEventBus";
import { isCuratedSearchAndPlaylistsEnabled } from "../../utils/featureFlag";

export default defineComponent({
  components: {
    AHAudioDownload,
    UiSaveSearchButton,
    AHSearchMatches,
    UiCheckBox,
    UiButton,
    UiMenu,
    UiList,
    UiListItem,
    UiIconButton,
    UiIcon,
    AHSortOptions
  },
  props: {
    isCreateCuratedSearch: {
      type: Boolean,
      default: false
    },
    isUpdateCuratedSearch: {
      type: Boolean,
      default: false
    },
    isSavedSearchApplied: {
      type: Boolean,
      default: false
    },
    audioItem: {
      type: Object as PropType<ViewModel.AudioItem>
    }
  },
  setup(props) {
    try {
      const eventBus = inject("eventBus") as Vue;
      const guid = computed(() => props.audioItem.guid);
      const headline = computed(() => props.audioItem.headline);
      const { isLoading } = useFetchAudioItems();
      const { audioitems, totalResults } = useAudioitems();
      const { shouldDisplaySearchMatches } = useSearchMatches();
      const { isMobile } = useBreakpoints();
      const {
        showCuratedSearchDialog,
        showCuratedSearchEditDialog
      } = useCuratedSearchDialog();
      const { showSaveSearchDialog } = useSaveSearchDialog();

      const shareViaEmail = () => {
        const subject = "dpa-Audio Hub Töne für dich";
        const generatePermalink = id => {
          return `${window.location.protocol}//${window.location.host}/app/audioitems/list/detail/${id}`;
        };

        const titleAndLink = selectedAudioItems.value.map(
          element =>
            "%0D%0A- " +
            `${encodeURIComponent(element.headline)}:%0D%0A ${generatePermalink(
              element.guid
            )}`
        );

        const bodyText =
          "Hallo, ich habe folgende Töne im dpa-Audio Hub gefunden, die für dich interessant sein könnten:%0D%0A" +
          `${titleAndLink}`;

        window.open(`mailto:?subject=${subject}&body=${bodyText}`, "_self");

        useMatomoEvent({
          category: "Share via email via Multiselect Menu",
          name: "Multiselect share via email",
          action:
            "When user clicks 'share via email' in multiselect menu => send event to count use of click"
        });
      };

      const { query } = useRouter(router)();

      const { toggleBulkFavorites } = useAudioItemOptions({ guid, headline });

      const isFavorites = computed(
        () => query.value.audioType === "amyFavorites"
      );

      const toggleAsFavorite = () => {
        const isFavorite = !isFavorites.value;

        const updatedAudioItems = selectedAudioItems.value.map(({ guid }) => ({
          audioItemGuid: guid,
          isFavorite: isFavorite
        }));

        const { validateAndRetrieveToken } = useAuthentication();

        toggleBulkFavorites(validateAndRetrieveToken(), updatedAudioItems);

        const existingFavorites = isFavorite
          ? selectedAudioItems.value.filter(item => item.isFavorite)
          : [];

        eventBus.$emit("show", {
          title: existingFavorites.length
            ? `${existingFavorites.length} ${
                existingFavorites.length > 1 ? "Töne sind" : "Ton ist"
              } bereits in deiner Favoritenliste`
            : `Als Favorit ${isFavorite ? "hinzugefügt" : "entfernt"}`,
          icon: "checkmark"
        });

        clearBulkSelection();

        const matomoPayload = isFavorites.value
          ? {
              category: "Multiselect delete from favorites",
              name: "Delete from favorites is selected via Multiselect Menu",
              action:
                'When user clicks "delete from favorites" in multiselect menu => send event to count the click'
            }
          : {
              category: "Multiselect add to favorites",
              name: "Add to favorites is selected via Multiselect Menu",
              action:
                'When user clicks "Add to favorites" in multiselect menu => send event to count the click'
            };
        useMatomoEvent(matomoPayload);
      };

      const isPlaylist = computed(() => !!query.value.playlistId);

      const togglePlaylistItems = (mode = "add") => () => {
        ManagePlaylistDialogEventBus.$emit(
          mode === "add"
            ? "addAudioItemsToPlaylist"
            : "removeItemsFromPlaylist",
          {
            audioItems: selectedAudioItems.value
          }
        );

        const matomoPayload = isPlaylist.value
          ? {
              category: "Multiselect delete from playlist",
              name: "Delete from playlist is selected via Multiselect Menu",
              action:
                'When user clicks "Delete from playlist" in multiselect menu => send event to count the click'
            }
          : {
              category: "Multiselect add to playlist",
              name: "Add to playlist is selected via Multiselect Menu",
              action:
                'When user clicks "Add to playlist" in multiselect menu => send event to count the click'
            };
        useMatomoEvent(matomoPayload);
      };

      const actionListItems = computed(() => [
        {
          title: "Via Email teilen",
          action: shareViaEmail
        },
        {
          title: isFavorites.value
            ? "Als Favorit entfernen"
            : "Als Favorit speichern",
          action: toggleAsFavorite
        },
        {
          title: isPlaylist.value
            ? "Aus Playlist entfernen"
            : "Zu Playlist hinzufügen",
          action: togglePlaylistItems(isPlaylist.value ? "remove" : "add")
        }
      ]);

      return {
        isLoading,
        audioitems,
        shouldDisplaySearchMatches,
        totalResults,
        showCuratedSearchDialog,
        showCuratedSearchEditDialog,
        showSaveSearchDialog,
        showBulkSelectionMenu,
        isMobile,
        allChecked,
        toggleAll,
        actionListItems,
        selectedAudioItems,
        clearBulkSelection,
        isCuratedSearchAndPlaylistsEnabled
      };
    } catch (error) {
      console.error(error);
    }
  }
});
