import { computed } from "@vue/composition-api";
import store from "@/store";

export default () => {
  /**
   * Toggle state for create curated search dialog
   */
  const showCuratedSearchDialog = computed({
    get() {
      return store.state.filters.showCuratedSearchDialog;
    },
    set(value: boolean) {
      store.commit("filters/setShowCuratedSearchDialog", value);
    }
  });

  /**
   * Toggle state for curated search edit dialog
   */
  const showCuratedSearchEditDialog = computed({
    get() {
      return store.state.filters.showCuratedSearchEditDialog;
    },
    set(value: boolean) {
      store.commit("filters/setShowCuratedSearchEditDialog", value);
    }
  });

  return { showCuratedSearchDialog, showCuratedSearchEditDialog };
};
