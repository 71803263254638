






































































































































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
  onMounted
} from "@/composition-api";

// Compositions
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import useAuthentication from "@/composition/useAuthentication";
import useAudioitems, { formatDateTime } from "@/composition/useAudioitems";
import useSetTranscriptFragmentSize from "@/composition/useSetTranscriptFragmentSize";
import useFetchAudioItems from "@/composition/useFetchAudioItems";
import useFetchItemsOnWatch from "@/composition/useFetchItemsOnWatch";
import useInfiniteScrolling from "@/composition/useInfiniteScrolling";
import useSearchMatches from "@/composition/useSearchMatches";
import useFilterSpeakers from "@/components/AHSearch/AdvancedFilters/useFilterSpeakers";
import { isExactLink } from "@/components/nav/navigation.composition";
import useExpandedSearch from "@/composition/useExpandedSearch";
import useBreakpoints from "@/composition/useBreakpoints";
import useGetUserRole from "@/composition/useGetUserRole";
import useMatomoSiteSearch from "@/composition/useMatomoSiteSearch";

// Components
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import AHAppBanner from "@/components/AHAppBanner.vue";
import AHSaveSearchDialog from "@/components/AHSaveSearchDialog/AHSaveSearchDialog.vue";
import AHNoSearchResults from "@/components/AHNoSearchResults.vue";
import AHAudioitem from "@/components/AHAudioitem/AHAudioitem.vue";
import AHAudioitemDetail from "@/components/AHAudioitem/AHAudioitemDetail.vue";
import AHFavorites from "@/components/AHFavorites/AHFavorites.vue";
import AHLoadingSkeletonItem from "@/components/AHLoadingSkeletonItem/AHLoadingSkeletonItem.vue";
import AHCuratedSearchPreview from "@/components/AHCuratedSearch/AHCuratedSearchPreview.vue";
import AHCuratedSearchItemDetail from "@/components/AHCuratedSearch/AHCuratedSearchItemDetail.vue";
import AHCuratedSearchDialog from "@/components/AHCuratedSearch/AHCuratedSearchDialog.vue";
import AHCuratedSearchEditDialog from "@/components/AHCuratedSearch/AHCuratedSearchEditDialog.vue";
import AHPlaylistItemDetail from "@/components/AHPlaylist/AHPlaylistItemDetail.vue";
import AHPublicListsSearch from "@/components/AHCuratedSearch/AHPublicListsSearch.vue";

import useFetchCuratedSearch from "@/composition/useFetchCuratedSearch";

import store from "@/store";
import router from "@/router";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useFetchPlaylists from "@/composition/useFetchPlaylists";
import AHAudioEdit from "@/components/AHAudioEdit/AHAudioEdit.vue";
import AHAudioDelete from "@/components/AHAudioDelete/AHAudioDelete.vue";
import AHAddToPlaylistDialog from "@/components/AHPlaylist/AHAddToPlaylistDialog.vue";
import AHCreatePlaylistDialog from "@/components/AHPlaylist/AHCreatePlaylistDialog.vue";
import AHFavoritesEmpty from "@/components/AHFavorites/AHFavoritesEmpty.vue";
import AHMoreThanFiftyDialog from "@/components/AHMoreThanFiftyDialog/AHMoreThanFiftyDialog.vue";
import AHFeedActions from "@/components/AHFeedActions/AHFeedActions.vue";

import UiCheckBox from "@/components/ui/UiCheckBox.vue";
import UiSpinner from "@dpa-id-components/ui-spinner";
import {
  showBulkSelectionMenu,
  selectedAudioItems,
  toggleItemSelection,
  isAudioitemSelected
} from "@/composition/useBulkSelection";
import useCuratedSearchDialog from "@/components/AHCuratedSearch/useCuratedSearchDialog";
import useSaveSearchDialog from "@/components/AHSaveSearchDialog/useSaveSearchDialog";
import ManagePlaylistDialogEventBus from "@/components/AHPlaylist/AHAddToPlaylistDialogEventBus";
import { showRemoveFromPlaylistDialog } from "@/components/AHPlaylist/playlistState";
import AHRemoveFromPlaylistDialog from "@/components/AHAudioitem/parts/RemoveFromPlaylistDialog.vue";
import useFilterRulesCount from "@/composition/useFilterRulesCount";
import useSearchPublicLists from "@/composition/useSearchPublicLists";
import useAudioTypeQuery from "@/composition/useAudioTypeQuery";
import { isCuratedSearchAndPlaylistsEnabled } from "../utils/featureFlag";

export default defineComponent({
  components: {
    AHMoreThanFiftyDialog,
    AHAddToPlaylistDialog,
    AHCreatePlaylistDialog,
    AHAudioDelete,
    AHAudioEdit,
    AHAudioitem,
    AHAudioitemDetail,
    AHLoadingSkeletonItem,
    AHNoSearchResults,
    AHSaveSearchDialog,
    AHAppBanner,
    AHCuratedSearchPreview,
    AHCuratedSearchItemDetail,
    AHCuratedSearchDialog,
    AHCuratedSearchEditDialog,
    UiTransitionOpacity,
    AHPlaylistItemDetail,
    AHFavorites,
    UiCheckBox,
    AHFeedActions,
    AHRemoveFromPlaylistDialog,
    UiSpinner,
    AHPublicListsSearch
  },
  props: {
    guid: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup() {
    /**
     * Composed functionality
     */
    const { validateAndRetrieveToken, isAuthenticated } = useAuthentication();
    const { params, query } = useRouter(router)();
    const { executeSearch } = useExpandedSearch();
    const { isMobile } = useBreakpoints();
    const { isAdminOrEditor } = useGetUserRole();
    const { curatedSearches } = useFetchCuratedSearch();
    const { playlists, fetchPlaylists } = useFetchPlaylists();

    const { appliedFullTextRules } = useFilterRulesCount();
    const { searchPlaylistNames, searchPublicLists } = useSearchPublicLists();

    /**
     * Fetch playlists for audio items
     */
    fetchPlaylists();
    /**
     * Set the fragmentSize parameter based on width of content
     */
    const contentTemplateRef = ref(null);
    useSetTranscriptFragmentSize({ contentTemplateRef });

    const { filterRules, isLoading, fetchAudioitems } = useFetchAudioItems();
    // Apply watchers to fetch audioitems dynamically
    useFetchItemsOnWatch();

    const {
      shouldDisplaySearchMatches,
      hasNoSearchMatches
    } = useSearchMatches();

    const {
      headline,
      audioitemPerson,
      created,
      audioitems,
      keyword,
      totalResults,
      activeAudioItem
    } = useAudioitems();

    const { shouldLoadMore } = useInfiniteScrolling();

    /**
     * Compute detail view
     */

    const moreThanFiftySelected = computed(
      () => selectedAudioItems.value.length > 50
    );

    const guid = computed(() => params.value.guid);

    /**
     * Compute detail view
     */

    const getActiveAudioItem: Ref<Definitions.AudioItem> = computed(
      () => store.getters["audioitems/getActiveAudioItem"]
    );

    watch(
      guid,
      () => {
        if (typeof guid.value === "string") {
          store.dispatch("audioitems/fetchAudioitemByGuid", {
            authJWT: validateAndRetrieveToken(),
            guid: guid.value,
            ...filterRules.value
          });
        } else {
          store.commit("audioitems/setActiveAudioitem", null);
        }
      },
      { immediate: true }
    );

    /*
     * Matomo internal search tracking EC-5520
     * watch returned audioitems to track fulltext filter rules as search terms
     */

    watch(
      audioitems,
      () => {
        const fulltextFilterRulesKeywords = appliedFullTextRules.value;
        const keywords = fulltextFilterRulesKeywords
          .map(({ values }) => values.join(" oder "))
          .join(", ");
        const numberOfResults = store.state.audioitems.totalResults;
        if (keyword.length) {
          useMatomoSiteSearch({ keywords, numberOfResults });
        }
      },
      { deep: true }
    );

    const audioItemById: Ref<Definitions.AudioItem> = computed(
      () => store.getters["audioitems/audioItemById"]
    );

    const { showSaveSearchDialog } = useSaveSearchDialog();

    const {
      showCuratedSearchDialog,
      showCuratedSearchEditDialog
    } = useCuratedSearchDialog();

    /**
     * Handle curated and saved search states
     */

    const isSavedSearchApplied = computed(() => {
      const { filterId, playlistId, audioType } = query.value;
      if (audioType && audioType === "amyFavorites") {
        return true;
      } else if (playlistId) {
        return true;
      } else if (!filterId) {
        return false;
      }
      return isExactLink({ filterId });
    });

    const curatedFilterId = computed(() => {
      return query.value.filterId;
    });

    const playlistId = computed(() => {
      return query.value.playlistId;
    });

    const showCreatePlaylistDialog = computed(
      () => store.getters["playlists/getShowCreatePlaylistDialog"]
    );

    const isCreateCuratedSearch = computed(
      () =>
        !isSavedSearchApplied.value &&
        !curatedFilterId.value &&
        isAdminOrEditor.value &&
        !playlistId.value
    );

    const isUpdateCuratedSearch = computed(
      () =>
        !isSavedSearchApplied.value &&
        curatedFilterId.value &&
        isAdminOrEditor.value &&
        !isMobile.value
    );

    const isCuratedSearchItemDetail = computed(
      () =>
        isCuratedSearchAndPlaylistsEnabled.value &&
        curatedSearches.value.length &&
        curatedFilterId.value &&
        isSavedSearchApplied.value
    );

    const { hasAppliedFilterRules } = useFilterRulesCount();

    const isCuratedSearchPreview = computed(
      () =>
        (!query.value.filterId &&
          !query.value.playlistId &&
          !hasAppliedFilterRules.value) ||
        (!hasAppliedFilterRules.value &&
          !shouldDisplaySearchMatches.value &&
          isCuratedSearchAndPlaylistsEnabled.value &&
          curatedSearches.value.length &&
          !isSavedSearchApplied.value &&
          audioitems.value.length &&
          !playlistId.value)
    );

    const isPlaylistItemDetail = computed(
      () =>
        playlistId.value &&
        !!playlists.value.find(({ id }) => id === playlistId.value)
    );

    const isFavorites = computed(
      () => query.value.audioType === "amyFavorites"
    );

    const noSearchResultsVariant = computed(() => {
      if (query.value.audioType === "amyFavorites") {
        return AHFavoritesEmpty;
      }
      return AHNoSearchResults;
    });

    const anySelected = computed(() => {
      return selectedAudioItems.value.map(element => element.guid).length > 0;
    });

    // Reset filterRules and fetch items when navigating away from a playlist
    watch(
      isPlaylistItemDetail,
      (val, oldVal) => {
        if (!!oldVal && !val) {
          const currentServices = store.state.filterRules.filterRules.filter(
            ({ type }) => type === "FILTER_GUID"
          );
          const filterRule = currentServices[0];
          if (filterRule) {
            store.commit("filterRules/removeFilterRule", { filterRule });
          }
          fetchAudioitems({ hasNewFilterRules: true });
        }
      },
      { immediate: true }
    );

    const { setSpeaker } = useFilterSpeakers();
    const handlePersonClick = personName => {
      setSpeaker(personName);
      executeSearch();
    };

    const closeDetailView = () => {
      const newParams = { ...params };
      delete newParams.value.guid;
      router.push({ name: "audioitems", newParams, query });
    };

    const showPolled = ref(false);
    const hasPolledItems = () =>
      store.state.audioitems.audioitems.some(audioitem => audioitem.polled);
    watch(audioitems, () => {
      if (hasPolledItems() && !showPolled.value) {
        showPolled.value = true;
      } else if (!hasPolledItems() && showPolled.value) {
        showPolled.value = false;
      }
    });

    const resetPolledItem = () =>
      store.commit("audioitems/resetPolledAudioItems");

    const removeFromPlaylistItems = ref([]);
    onMounted(() => {
      ManagePlaylistDialogEventBus.$on(
        "removeItemsFromPlaylist",
        ({ audioItems }) => {
          removeFromPlaylistItems.value = audioItems;
          showRemoveFromPlaylistDialog.value = true;
        }
      );
    });

    const { hasOnlyAudioTypeFilterRules } = useAudioTypeQuery();

    return {
      audioItemById,
      audioitemPerson,
      audioitems,
      contentTemplateRef,
      created: audioitem => formatDateTime(created(audioitem)),
      getActiveAudioItem,
      handlePersonClick,
      closeDetailView,
      hasNoSearchMatches,
      headline,
      isAuthenticated,
      isLoading,
      isSavedSearchApplied,
      searchPlaylistNames,
      shouldDisplaySearchMatches,
      shouldLoadMore,
      showSaveSearchDialog,
      showCuratedSearchDialog,
      showCuratedSearchEditDialog,
      totalResults,
      useMatomoEvent,
      showPolled,
      resetPolledItem,
      hasPolledItems,
      activeAudioItem,
      curatedFilterId,
      isCuratedSearchItemDetail,
      isMobile,
      isCreateCuratedSearch,
      isUpdateCuratedSearch,
      isCuratedSearchPreview,
      isPlaylistItemDetail,
      playlistId,
      searchPublicLists,
      showCreatePlaylistDialog,
      isFavorites,
      noSearchResultsVariant,
      showBulkSelectionMenu,
      selectedAudioItems,
      toggleItemSelection,
      anySelected,
      moreThanFiftySelected,
      isAudioitemSelected,
      removeFromPlaylistItems,
      showRemoveFromPlaylistDialog,
      hasAppliedFilterRules,
      hasOnlyAudioTypeFilterRules,
      isCuratedSearchAndPlaylistsEnabled
    };
  }
});
