

















































































































































































































































import { PropType } from "vue";
import { defineComponent, computed, ref, inject } from "@/composition-api";
import store from "@/store";
import useSharingOptions from "@/composition/useSharingOptions";
import AHPlayButton from "@/components/AHPlayButton/AHPlayButton.vue";
import AHAudioPlayerEventBus from "@/components/AHAudioPlayer/AHAudioPlayerEventBus";
import AHWaveform from "@/components/AHWaveform/AHWaveform.vue";
import useBreakpoints from "@/composition/useBreakpoints";
import useRouter from "@/composition/useRouter";
import UiIconLink from "@/assets/vectors/icons/link-2-outline.svg?inline";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiIcon from "@dpa-id-components/ui-icon";
import UiIconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import UiIconDownload from "@/assets/vectors/icons/download.svg?inline";
import UiIconInfo from "@/assets/vectors/icons/info.svg?inline";
import UiButton from "@/components/ui/UiButton.vue";
import UiBadge from "@/components/ui/UiBadge.vue";
import UiLineClamp from "@/components/ui/UiLineClamp.vue";
import UiHighlight from "@/components/ui/UiHighlight.vue";
import UiBanner from "@/components/ui/UiBanner.vue";
import { Route } from "vue-router";
import useModalScrollPrevention from "@/composition/useModalScrollPrevention";
import AHAudioDownload from "@/components/AHAudioDownload/AHAudioDownload.vue";
import { Atmo, Interview, OTon } from "./icons";
import { getAudioIcon } from "./shared/utils";
import { Keywords } from "./parts";
import updateSearch from "./updateKeyWordSearch";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useConnectRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import useGuid from "./shared/useGuid";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import useAuthentication from "@/composition/useAuthentication";
import useAudioItemOptions from "@/composition/useAudioItemOptions";
import useGetUserRole from "@/composition/useGetUserRole";

export default defineComponent({
  components: {
    AHAudioDownload,
    AHPlayButton,
    AHWaveform,
    Atmo,
    Interview,
    Keywords,
    OTon,
    UiBadge,
    UiButton,
    UiHighlight,
    UiIconClose,
    UiIcon,
    UiIconDownload,
    UiIconLink,
    UiIconSpinner,
    UiLineClamp,
    UiBanner,
    UiIconInfo,
    UiTooltip,
    UiButtonIcon
  },
  props: {
    audioItem: {
      type: Object as PropType<ViewModel.AudioItem>
    },
    rawAudioItem: {
      type: Object as PropType<ViewModel.AudioItem>
    }
  },
  setup(props, { emit }) {
    /**
     * Destructure audio item props
     */
    const eventBus: Vue = inject("eventBus");
    const audioContent = computed(() => props.audioItem.audioContent);
    const audioitemType = computed(() => props.audioItem.audioitemType);
    const description = computed(() => props.audioItem.description);
    const duration = computed(() => props.audioItem.duration);
    const guid = computed(() => props.audioItem.guid);
    const headline = computed(() => props.audioItem.headline);
    const highlights = computed(() => props.audioItem.highlights);
    const keywords = computed(() => props.audioItem.keywords);
    const persons = computed(() => props.audioItem.persons);
    const transcript = computed(() => props.audioItem.transcript);
    const type = computed(() => props.audioItem.type);
    const version_created = computed(() => props.audioItem.version_created);
    const content_created = computed(() => props.audioItem.content_created);
    const waveform = computed(() => props.audioItem.waveform);
    const isFavorite = computed(() => props.rawAudioItem.isFavorite);
    const showFavoriteOption = computed(
      () =>
        store.state.stageconfig.config &&
        store.state.stageconfig.config.features.enableFavorites
    );

    const { isMobile } = useBreakpoints();

    function deleteParamFromRoute(paramName: string, route: Route) {
      const { params, query } = route;

      delete params[paramName];

      return { name: "audioitems", params, query };
    }

    /*
     * Compute back link
     */

    const { router } = useRouter();
    const audioItemsListRoute = computed(() =>
      deleteParamFromRoute("guid", router.currentRoute)
    );

    const seek = ref(0);
    const { query } = useConnectRouter(router)();
    seek.value = query.value.t;

    /**
     * Compute icon from type
     * TODO: DRY w. list audio item list view
     */

    const audioIcon = computed(() => getAudioIcon(props.audioItem.type));

    /**
     * TODO: Computed formatters, need to decide where to put this.
     */

    const personsCommaSeperated = computed(() => {
      if (persons.value && persons.value.length) {
        return persons.value.join(", ");
      }

      return null;
    });

    /**
     * Map labels to meta data values
     */

    const metaData = computed(() => {
      return [
        {
          label: "Person",
          value: personsCommaSeperated.value
        },
        {
          label: "Aufnahmedatum",
          value: content_created.value
        },
        {
          label: "Veröffentlichungsdatum",
          value: version_created.value
        }
      ];
    });

    const { share } = useSharingOptions({
      guid,
      audioContent,
      headline
    });

    const waveformProgress = computed(() => {
      const { audioId, progress } = store.state.audioPlayer;
      const residref = props.audioItem?.audioContent?.residref;

      if (audioId === residref) {
        return progress;
      } else if (seek.value !== 0) {
        return Math.round(
          (seek.value / parseInt(audioContent.value.duration)) * 100
        );
      }

      return 0;
    });

    const breakpoint = useBreakpoints();

    /**
     * Plays or seeks in audio
     */
    function handleProgressChange(e): void {
      const { audioId } = store.state.audioPlayer;

      if (audioContent.value.residref !== audioId) {
        AHAudioPlayerEventBus.$emit("play", {
          title: headline.value,
          author: persons.value.join(", "),
          guid: guid.value,
          waveform: waveform.value,
          audioContent: audioContent.value,
          initialSeek: Math.round(
            parseInt(audioContent.value.duration) * (e / 100)
          )
        });
      } else {
        AHAudioPlayerEventBus.$emit("setProgress", e);
      }
    }

    function handleCloseClick() {
      router.push(deleteParamFromRoute("guid", router.currentRoute));
    }

    const handlePersonClick = personName => {
      emit("personClickDetail", personName);
      useMatomoEvent({
        category: "Detail View",
        action: "Click Speaker",
        name: "Speaker"
      });
    };

    useModalScrollPrevention();

    const dialogStyles = computed(() => {
      const { isActive } = store.state.audioPlayer;
      let space = 2;

      if (isActive) {
        space = isMobile.value ? 16 : 10;
      }

      return { "max-height": `calc(100% - ${space}rem)` };
    });

    const trackKeywordsEvent = () => {
      useMatomoEvent({
        category: "Detail View",
        action: "Click Keyword",
        name: "Keywords"
      });
    };

    const { isExternal } = useGuid(props.audioItem.guid);

    const starSwitch = computed(() => {
      return isFavorite.value ? "starFilled" : "star";
    });

    const { toggleFavorites } = useAudioItemOptions({ guid, headline });

    const toggleFavorite = () => {
      const { validateAndRetrieveToken } = useAuthentication();

      toggleFavorites(validateAndRetrieveToken(), props.rawAudioItem);
      eventBus.$emit("show", {
        title: `Als Favorit ${
          !props.rawAudioItem.isFavorite ? "hinzugefügt" : "entfernt"
        }`,
        icon: "checkmark"
      });
    };

    const favoritesMatomo = computed(() => {
      return !isFavorite.value
        ? useMatomoEvent({
            category: "Detail View",
            action: 'Unclick "Zu Favoriten hinzugefügt" in Detail View',
            name: `Remove from favorites in Detail View`
          })
        : useMatomoEvent({
            category: "Detail View",
            action: 'Click "Add to favorites" in Detail View',
            name: `Add to favorites in Detail View`
          });
    });

    const favoriteTooltip = computed(() => {
      return isFavorite.value
        ? "Als Favorit entfernen"
        : "Zu Favoriten hinzufügen";
    });

    const { isReader } = useGetUserRole();

    return {
      audioContent,
      audioIcon,
      audioItemsListRoute,
      audioitemType,
      description,
      duration,
      guid,
      handleCloseClick,
      handlePersonClick,
      handleProgressChange,
      headline,
      highlights,
      keywords,
      metaData,
      persons,
      personsCommaSeperated,
      screen: breakpoint.screen,
      isMobile,
      isFavorite,
      share,
      transcript,
      type,
      updateSearch,
      version_created,
      waveform,
      waveformProgress,
      dialogStyles,
      trackKeywordsEvent,
      seek,
      isExternal,
      starSwitch,
      toggleFavorite,
      favoritesMatomo,
      favoriteTooltip,
      showFavoriteOption,
      isReader
    };
  }
});
