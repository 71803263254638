





















import { defineComponent, computed } from "@/composition-api";
import store from "@/store";
import AHAudioPlayerEventBus from "@/components/AHAudioPlayer/AHAudioPlayerEventBus";
import IconPlay from "@/assets/vectors/icons/play.svg?inline";
import IconPause from "@/assets/vectors/icons/pause.svg?inline";
import IconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import { PropType } from "vue";

export default defineComponent({
  name: "AHPlayButton",
  props: {
    title: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    guid: {
      type: String,
      required: true
    },
    waveform: {
      type: Array,
      required: true
    },
    audioContent: {
      type: Object as PropType<Definitions.RemoteContent>,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    initialSeekInSeconds: {
      type: Number,
      default: null
    },
    isFavorite: {
      type: Boolean,
      required: true
    }
  },
  components: {
    IconPause,
    IconPlay,
    IconSpinner
  },
  setup(props) {
    const duration = computed(() => {
      // weirdly the duration coming from the API is a string, so we have to convert :)
      const secs = parseInt(props.audioContent?.duration, 10);
      const minutes = Math.floor(secs / 60) || 0;
      const seconds = secs - minutes * 60 || 0;

      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    });

    const isPlaying = computed(() => {
      const { isPlaying: playingState, audioId } = store.state.audioPlayer;

      return audioId === props.audioContent?.residref ? playingState : false;
    });

    const isLoading = computed(() => {
      const { isLoading: loadingState, audioId } = store.state.audioPlayer;

      return audioId === props.audioContent?.residref ? loadingState : false;
    });

    const playOrPause = () => {
      if (isPlaying.value) {
        AHAudioPlayerEventBus.$emit("pause");
      } else {
        // TODO: this should be more explicit: what exact payload is expected?
        AHAudioPlayerEventBus.$emit("play", {
          title: props.title,
          guid: props.guid,
          author: props.author,
          audioContent: props.audioContent,
          initialSeek: props.initialSeekInSeconds,
          waveform: props.waveform,
          isFavorite: props.isFavorite
        });
      }
    };

    return {
      duration,
      isPlaying,
      isLoading,
      playOrPause
    };
  }
});
