import { computed } from "@/composition-api";
import store from "@/store";

export default () => {
  const speakerFilterRules = computed(
    () => store.getters["filterRules/speakerFilterRules"]
  );

  const firstSpeaker = computed(() => speakerFilterRules.value?.[0]);

  const setSpeaker = (speakerName: string) => {
    const filterRule = {
      type: "FILTER_FULLTEXT_FIELDS",
      values: [speakerName],
      fields: ["FIELD_PERSON"]
    };

    if (!firstSpeaker.value) {
      store.commit("filterRules/addFilterRule", { filterRule });
    } else {
      const filterRules = [firstSpeaker.value];
      const updatedFilterRules = [filterRule];

      store.commit("filterRules/replaceFilterRules", {
        filterRules,
        updatedFilterRules
      });
    }
  };

  return {
    speakerFilterRules,
    firstSpeaker,
    setSpeaker
  };
};
