/* eslint-disable no-underscore-dangle */
/**
 * Declaring the matomo window object for typescript.
 */
declare global {
  interface Window {
    _paq: any;
  }
}

export default ({
  keywords,
  numberOfResults
}: {
  keywords: string;
  numberOfResults: number;
}) => {
  if (window._paq !== undefined) {
    if (numberOfResults === 0) {
      window._paq.push(["trackSiteSearch", keywords, false, 0]);
    } else {
      window._paq.push(["trackSiteSearch", keywords, numberOfResults]);
    }
  }
};
