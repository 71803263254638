import { onMounted, watch, ref, computed } from "@/composition-api";
import useFetchAudioItems from "./useFetchAudioItems";
import useAudioitems from "./useAudioitems";

const getIsBottomOfWindow = (containerElement: HTMLElement) => {
  const { scrollTop, scrollHeight, offsetHeight } = containerElement;
  const height = scrollHeight - offsetHeight;

  return scrollTop >= height - 500;
};

export default () => {
  const { fetchAudioitems } = useFetchAudioItems();

  // For inifinite scrolling
  const atBottomOfWindow = ref(false);

  const { audioitems, totalResults } = useAudioitems();

  onMounted(() => {
    const mainElement = document.querySelector("main");
    mainElement.onscroll = () => {
      const bottomOfWindow = getIsBottomOfWindow(mainElement);
      atBottomOfWindow.value = bottomOfWindow;
    };
  });

  const shouldLoadMore = computed(
    () => atBottomOfWindow.value && audioitems.value.length < totalResults.value
  );

  watch(atBottomOfWindow, () => {
    // Don't load more if the store already contains the total number of items
    if (shouldLoadMore.value) {
      fetchAudioitems({
        hasNewFilterRules: false,
        hasNewSort: false
      });
    }
  });

  return { shouldLoadMore };
};
