











































































import { computed, defineComponent, onMounted, ref } from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiMenu from "@/components/ui/UiMenu.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiAutocomplete from "@dpa-id-components/ui-autocomplete";
import store from "@/store";
import useAuthentication from "@/composition/useAuthentication";
import { inject } from "@/composition-api";
import { getWordsOrPhrases } from "@/components/AHSearch/AdvancedFilters/advancedFullTextFilters.utils";
import { format, parse } from "date-fns";
import AHAudioEditEventBus from "@/components/AHAudioEdit/AHAudioEditEventBus";

export default defineComponent({
  name: "AHAudioEdit",
  components: {
    UiButton,
    UiDialog,
    UiIconClose,
    UiInput,
    UiTextArea,
    UiAutocomplete,
    UiMenu
  },
  setup(props, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const showEditDialog = ref(false);
    const guid = ref("");
    const title = ref(String);
    const description = ref(String);
    const persons = ref(Array);
    const keywords = ref(Array);
    const content_created = ref("");

    // formulate service field from selected Audio-Types
    const genre = ref(String);
    const service = [];
    const audioTypes = ref([
      {
        label: "O-Ton",
        value: { name: "O-Ton", uri: "http://g2.dpa.com/cv/service/aoton" }
      },
      {
        label: "Interview",
        value: { name: "Interview", uri: "http://g2.dpa.com/cv/service/atalk" }
      },
      {
        label: "Atmo",
        value: { name: "Atmo", uri: "http://g2.dpa.com/cv/service/aatmo" }
      },
      {
        label: "Umfrage",
        value: { name: "Umfrage", uri: "http://g2.dpa.com/cv/service/aumfrage" }
      }
    ]);
    const handleOptionClick = option => {
      emit("option-select", {
        value: option.label
      });
      genre.value = option.label;
      if (service.length !== 0) {
        service.length = 0;
      }
      service.push(option.value);
    };

    const closeDialog = () => {
      showEditDialog.value = false;
    };
    const openDialog = () => {
      showEditDialog.value = true;
    };

    // load formated meta data of audio-item in the edit-dialog
    const audioItem = computed(() =>
      store.getters["audioitems/audioItemById"](guid.value)
    );

    const adjustContentCreated = content_created => {
      if (content_created && content_created.length === 10) {
        return `${content_created} 00:00`;
      }
      return content_created;
    };

    const loadMetaData = () => {
      openDialog();
      title.value = audioItem.value.headline;
      description.value = audioItem.value.description;
      keywords.value = audioItem.value.keywords;
      persons.value = audioItem.value.persons;
      genre.value = audioItem.value.type;
      content_created.value = format(
        parse(
          adjustContentCreated(audioItem.value.content_created),
          "dd.MM.yyyy HH:mm",
          new Date()
        ),
        "yyyy-MM-dd'T'HH:mm:ss"
      );
    };

    /**
     * Saving the changes, close dialog and update the store-info.
     **/
    const saveMetaData = async () => {
      closeDialog();
      const { validateAndRetrieveToken: authJWT } = useAuthentication();

      // get the row meta-data of the selected audio-item
      const audioMetaData = store.state.audioitems.audioitems.find(
        item => item.guid === guid.value
      );

      // formulate subject from speaker input field
      const names = getWordsOrPhrases(persons.value.toString());
      const nonSpeakerSubjectItems = audioMetaData.subject.filter(
        item => item.type !== "http://cv.iptc.org/newscodes/cpnature/person"
      );
      const subjectItems = computed(() => {
        const subject = [];
        for (const name of names) {
          subject.push({
            literal: null,
            name: name,
            type: "http://cv.iptc.org/newscodes/cpnature/person",
            uri: null
          });
        }
        for (const item of nonSpeakerSubjectItems) {
          subject.push(item);
        }
        return subject;
      });

      const serviceSelected = computed(() => {
        if (service.length == 0) {
          return audioMetaData.service;
        } else {
          return service;
        }
      });

      store.dispatch("audioitems/saveAudioitemByGuid", {
        authJWT: authJWT(),
        audioObject: {
          version: audioMetaData.version,
          guid: guid.value,
          headline: [title.value],
          description: [
            {
              lang: audioMetaData.description[0].lang,
              role: audioMetaData.description[0].role,
              text: description.value
            }
          ],
          content_created: format(
            new Date(content_created.value),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          ),
          keyword: getWordsOrPhrases(keywords.value.toString()),
          service: serviceSelected.value,
          subject: subjectItems.value
        }
      });
      eventBus.$emit("show", {
        title: `Ton aktualisiert`,
        icon: "checkmark"
      });
    };

    onMounted(() => {
      AHAudioEditEventBus.$on("showEditModal", payload => {
        title.value = payload.headline || "";
        guid.value = payload.guid.value;
        loadMetaData();
      });
    });

    return {
      closeDialog,
      openDialog,
      loadMetaData,
      saveMetaData,
      showEditDialog,
      title,
      description,
      persons,
      service,
      genre,
      keywords,
      audioTypes,
      handleOptionClick,
      content_created
    };
  }
});
