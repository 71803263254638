

















































































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
  inject,
  reactive,
  watch
} from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiIconDelete from "@/assets/vectors/icons/delete.svg?inline";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";
import store from "@/store";
import router from "@/router";
import useImageUpload from "@/composition/useImageUpload";
import useUpdateImageIdentifier from "@/composition/useUpdateImageIdentifier";
import useBreakpoints from "@/composition/useBreakpoints";
import { useResetFilterRules } from "@/components/nav/navigation.composition";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiInput,
    UiTextArea,
    UiToggle,
    UiButton,
    UiIconDelete,
    UiErrorMessage
  },
  props: {
    curatedFilterId: {
      type: String,
      required: true,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const { isMobile } = useBreakpoints();
    const { resetFilterRules } = useResetFilterRules();
    const {
      imageFile,
      imageUrl,
      imageName,
      handleSelectedImage
    } = useImageUpload();

    const {
      handleImageIdentifierUpload,
      handleImageIdentifierDelete
    } = useUpdateImageIdentifier();

    const userProfile = computed(() => store.state.user?.profile);
    const filterRules = computed(() => store.state.filterRules.filterRules);
    const authJWT = store.state.auth.token;

    const filterId = computed(() => props.curatedFilterId);
    const curatedFilterId = computed(() => {
      return filterId.value;
    });
    const curatedFilter = computed(() =>
      store.getters["filters/getFilterById"](curatedFilterId.value)
    );

    const inputRef = ref();
    const title = ref(curatedFilter.value.name as string);
    const description = ref(curatedFilter.value.description as string);
    const imageIdentifier = ref(curatedFilter.value.imageIdentifier as string);
    const curatedItemIsPublic = ref(curatedFilter.value.isCuratedSearchActive);

    const curatedSearchImageURL = computed(
      () => store.state.stageconfig.config.curatedSearchImageURL
    );
    const curatedImgUrl = ref(
      `${curatedSearchImageURL.value}/${imageIdentifier.value}`
    );
    const changeCuratedImgUrl = reactive({ curatedImgUrl });

    onMounted(() => {
      inputRef.value?.$refs?.input?.focus();
    });

    watch(
      curatedFilter,
      () => {
        title.value = curatedFilter.value.name;
        description.value = curatedFilter.value.description;
        curatedItemIsPublic.value = curatedFilter.value.isCuratedSearchActive;
        imageIdentifier.value = curatedFilter.value.imageIdentifier;
      },
      { deep: true }
    );

    const updateImageNameValue = computed(() => {
      return imageName ? imageName.value : imageIdentifier.value;
    });

    const curatedSearchImageValue = computed(() => {
      return !imageIdentifier.value ? imageName.value : imageIdentifier.value;
    });

    const updateCuratedSearchFilter = computed(() => ({
      ...curatedFilter.value,
      id: curatedFilter.value.id,
      name: title.value,
      description: description.value,
      imageIdentifier:
        updateImageNameValue.value || curatedSearchImageValue.value,
      owner: userProfile.value?.sub,
      filterRules: filterRules.value,
      curatedSearch: true,
      isCuratedSearchActive: curatedItemIsPublic.value
    }));

    function onClearSelectedImage() {
      imageFile.value = "";
      imageName.value = "";
      imageUrl.value = "";
      changeCuratedImgUrl.curatedImgUrl = "";
    }

    const updateCuratedSearch = () => {
      store.dispatch("filters/updateFilter", {
        filter: updateCuratedSearchFilter.value,
        authJWT
      });

      router.push({
        name: "audioitems",
        query: { filterId: updateCuratedSearchFilter.value.id }
      });

      // upload ImageIdentifier
      handleImageIdentifierUpload(imageFile, imageName);

      eventBus.$emit("show", {
        title: "Kuratierte Suche aktualisiert",
        icon: "checkmark"
      });
      emit("close");
    };

    const deleteCuratedSearchFilter = () => {
      // delete Curated Search
      store.dispatch("filters/deleteFilter", {
        filter: updateCuratedSearchFilter.value,
        authJWT
      });

      // delete imageIdentifier
      handleImageIdentifierDelete(imageIdentifier);

      router.push({ path: "/curatedSearches" });

      resetFilterRules();

      eventBus.$emit("show", {
        title: `"${title.value}" wurde gelöscht`,
        icon: "delete"
      });

      emit("close");
    };

    return {
      title,
      description,
      curatedItemIsPublic,
      imageIdentifier,
      updateCuratedSearchFilter,
      deleteCuratedSearchFilter,
      curatedFilterId,
      curatedFilter,
      curatedImgUrl,
      updateCuratedSearch,
      inputRef,
      isMobile,
      handleSelectedImage,
      imageUrl,
      imageFile,
      imageName,
      handleImageIdentifierUpload,
      handleImageIdentifierDelete,
      onClearSelectedImage
    };
  }
});
