















































import { defineComponent } from "@vue/composition-api";
import UiImageCard from "@/components/ui/UiImageCard.vue";

export default defineComponent({
  components: { UiImageCard },
  props: {
    publicLists: {
      type: Array,
      default: () => []
    },
    hasAudioitems: {
      type: Boolean,
      default: true
    }
  }
});
