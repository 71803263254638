




























import { defineComponent, onMounted, ref } from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiButton from "@/components/ui/UiButton.vue";
import store from "@/store";
import useAuthentication from "@/composition/useAuthentication";
import { inject } from "@/composition-api";
import AHAudioDeleteEventBus from "@/components/AHAudioDelete/AHAudioDeleteEventBus";

export default defineComponent({
  name: "AHAudioDelete",
  components: {
    UiButton,
    UiDialog
  },
  setup() {
    const eventBus: Vue = inject("eventBus");
    const guid = ref(String);
    const headline = ref(String);
    const showDeleteDialog = ref(false);
    const closeDialog = () => {
      showDeleteDialog.value = false;
    };
    const openDialog = () => {
      showDeleteDialog.value = true;
    };

    /**
     * Do the actual delete, close dialog and update the store-info.
     **/

    const deleteItem = async () => {
      closeDialog();
      const { validateAndRetrieveToken: authJWT } = useAuthentication();
      store.dispatch("audioitems/deleteAudioitemByGuid", {
        authJWT: authJWT(),
        guid: guid.value
      });
      eventBus.$emit("show", {
        title: `Ton wurde gelöscht`,
        icon: "delete"
      });
    };

    onMounted(() => {
      AHAudioDeleteEventBus.$on("showDeleteModal", payload => {
        headline.value = payload.headline || "";
        guid.value = payload.guid.value;
        openDialog();
      });
    });

    return {
      closeDialog,
      openDialog,
      deleteItem,
      showDeleteDialog,
      headline
    };
  }
});
