var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col md:pl-4",class:{ 'pt-4': !_vm.hasAudioitems }},[_c('span',{staticClass:"searchlist-header text-small font-semibold uppercase leading-5 text-gray-500"},[_vm._v(" Playlists und Suchvorschläge ")]),_c('div',{staticClass:"AHPublicListsSearch overflow-x-hidden overflow-x-scroll pb-4",staticStyle:{"scroll-snap-type":"x mandatory"}},[_c('div',{staticClass:"flex pt-2 pb-2"},_vm._l((_vm.publicLists),function(ref,index){
var label = ref.label;
var src = ref.src;
var id = ref.id;
var type = ref.type;
var to = ref.to;
return _c('router-link',{key:index,attrs:{"custom":"","to":to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('ui-image-card',{staticClass:"overflow-hidden cursor-pointer flex-grow-0 flex-shrink-0 mr-2 last:mr-0",staticStyle:{"width":"240px","max-height":"160px","scroll-snap-align":"start","padding-bottom":"160px"},attrs:{"img-url":src,"title":label,"is-public-list-search":""},nativeOn:{"click":function($event){return (function (args) {
              _vm.$store.commit('filterRules/setFullTextSearch', '');
              navigate(args);
            })($event)}}})]}}],null,true)})}),1)]),(_vm.hasAudioitems)?_c('span',{staticClass:"searchlist-header text-small font-semibold uppercase leading-5 text-gray-500 mb-2"},[_vm._v(" Töne ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }