




































import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import {
  computed,
  defineComponent,
  inject,
  PropType
} from "@vue/composition-api";
import router from "@/router";
import useManagePlaylists from "@/composition/useManagePlaylists";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import UiButton from "@/components/ui/UiButton.vue";
import store from "@/store";
import { clearBulkSelection } from "@/composition/useBulkSelection";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiTransitionOpacity,
    UiButton
  },
  props: {
    audioItems: {
      type: Array as PropType<ViewModel.AudioItem[]>
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const { query } = useRouter(router)();
    const playlist = computed(() =>
      query.value.playlistId
        ? store.state.playlists.playlists.find(
            ({ id }) => id === query.value.playlistId
          )
        : undefined
    );
    const managePlaylists = useManagePlaylists();

    const removeAudioItemsFromPlaylist = () => {
      managePlaylists.removeAudioItemsFromPlaylist({
        audioItems: props.audioItems,
        playlist: playlist.value
      });

      props.audioItems.forEach(({ guid }) =>
        store.commit("audioitems/deleteAudioitem", guid)
      );

      eventBus.$emit("show", {
        title: `Inhalt aus Playlist gelöscht`,
        icon: "delete"
      });

      emit("close");

      clearBulkSelection();
    };

    return { removeAudioItemsFromPlaylist, playlist };
  }
});
