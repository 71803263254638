








import { defineComponent } from "@/composition-api";
import UiGridLayout from "@/components/ui/UiGridLayout.vue";

export default defineComponent({
  components: { UiGridLayout }
});
