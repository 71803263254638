














import { defineComponent } from "@/composition-api";
import DiamondIcon from "@/assets/vectors/icons/diamond.svg?inline";

export default defineComponent({
  components: { DiamondIcon },
  props: {
    iconColor: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
});
