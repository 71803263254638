






















































































import { defineComponent, ref } from "@/composition-api";
import UiBadge from "@/components/ui/UiBadge.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default defineComponent({
  components: {
    UiBadge,
    UiButton
  },
  props: {
    imgUrl: {
      type: String,
      required: true,
      default: ""
    },
    title: {
      type: String,
      required: true,
      default: ""
    },
    updateText: {
      type: String,
      required: true,
      default: ""
    },
    description: {
      type: String,
      required: true,
      default: ""
    },
    icon: {
      type: Object,
      required: false
    },
    iconText: {
      type: String,
      required: false,
      default: ""
    },
    badge: {
      type: String,
      required: true,
      default: ""
    },
    label: {
      type: String,
      required: true,
      default: "Ansicht bearbeiten"
    },
    isAdminOrEditor: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const isLoading = ref(true);

    return {
      isLoading
    };
  }
});
