

































import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from "@/composition-api";
import store from "@/store";
import { format } from "date-fns";
import UiImageCardDetails from "../ui/UiImageCardDetails.vue";
import UiBadge from "@/components/ui/UiBadge.vue";
import useFetchPlaylists from "@/composition/useFetchPlaylists";
import { ListIcon } from "@/components/nav/icons";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import AHEditPlaylistDialog from "./AHEditPlaylistDialog.vue";
import useGetUserRole from "@/composition/useGetUserRole";

export default defineComponent({
  components: {
    UiImageCardDetails,
    UiBadge,
    ListIcon,
    UiTransitionOpacity,
    AHEditPlaylistDialog
  },
  props: {
    playlistId: {
      type: String,
      required: true,
      default: undefined
    }
  },

  setup(props) {
    const playlistId = computed(() => props.playlistId);

    const playlistImageURL = computed(
      () => store.state.stageconfig.config.curatedSearchImageURL
    );

    const playlist = computed(() =>
      store.getters["playlists/getPlaylistById"](playlistId.value)
    );

    const setEmptyPlaylist = () => {
      store.commit("audioitems/setAudioitems", []);
      store.commit("audioitems/setIsLoading", false);
    };

    const audioItems = playlist.value?.audioItems;
    const { fetchPlaylistItems } = useFetchPlaylists();
    if (audioItems.length) {
      fetchPlaylistItems(audioItems);
    } else {
      setEmptyPlaylist();
    }

    // Re-fetch items on playlist items updates
    watch(
      playlist,
      () => {
        if (playlist.value.audioItems.length) {
          fetchPlaylistItems(playlist.value.audioItems);
        } else {
          setEmptyPlaylist();
        }
      },
      { deep: true }
    );

    const playlistName = computed(() => {
      return playlist.value?.name;
    });
    const playlistDescription = computed(
      () => playlist.value?.description as string
    );

    const imageIdentifier = computed(
      () => playlist.value?.imageIdentifier as string
    );
    const updatedDate = playlist.value?.updatedAt
      ? computed(() => format(new Date(playlist.value.updatedAt), "dd.MM.yyyy"))
      : ref(undefined);

    const updatedTime = playlist.value?.updatedAt
      ? computed(() => format(new Date(playlist.value.updatedAt), "HH:mm"))
      : ref(undefined);

    const imageURL = computed(() => {
      return `${playlistImageURL.value}/${imageIdentifier.value}`;
    });

    const editPlaylist = reactive({
      showDialog: false
    });

    const { isAdminOrEditor } = useGetUserRole();

    return {
      playlistId,
      playlistName,
      playlistDescription,
      imageURL,
      updatedDate,
      updatedTime,
      ListIcon,
      editPlaylist,
      isAdminOrEditor
    };
  }
});
