var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col pt-4 md:pl-4 md:pt-7"},[_c('div',{staticClass:"flex justify-between pb-1"},[_c('span',{staticClass:"text-base font-medium leading-5 text-gray-900"},[_vm._v(" Playlists und Suchvorschläge ")]),_c('span',[_c('chevron-down-icon',{staticClass:"w-6 h-6 text-gray-800 fill-current cursor-pointer rounded-full hover:bg-gray-300",class:_vm.transformChevronIcon,on:{"click":function($event){_vm.isCuratedSearchItemsVisible = !_vm.isCuratedSearchItemsVisible}}})],1)]),_c('p',{staticClass:"hidden md:flex text-sm font-normal leading-5 text-gray-900"},[_vm._v(" Hier finden Sie von der dpa-Redaktion erstellte Sammlungen von Tönen zu aktuellen und zeitlosen Themen. ")]),(_vm.mixedCuratedAndPlaylists.length)?[(_vm.isCuratedSearchItemsVisible)?_c('div',[_c('div',{staticClass:"flex md:grid gap-2 md:gap-6 md:grid-cols-2 lg:grid-cols-3 pb-6 pt-4 overflow-x-scroll scrolling-touch md:overflow-x-hidden",staticStyle:{"scroll-snap-type":"x mandatory"}},_vm._l((_vm.mixedCuratedAndPlaylists),function(ref,index){
var label = ref.label;
var src = ref.src;
var id = ref.id;
var isCuratedSearchActive = ref.isCuratedSearchActive;
var onCardClick = ref.onCardClick;
return _c('ui-image-card',{key:index,staticClass:"overflow-hidden cursor-pointer",staticStyle:{"min-width":"250px","scroll-snap-align":"start"},attrs:{"img-url":src,"title":label,"badge":_vm.showBadge(isCuratedSearchActive)},nativeOn:{"click":function($event){onCardClick(id);
            _vm.useMatomoEvent({
              category: 'Startpage Preview',
              action: 'Click preview teaser',
              name: ("Home Page (" + label + ")")
            });}}})}),1)]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }