













import { defineComponent } from "@/composition-api";
import UiInfoContent from "@dpa-id-components/ui-info-content";
import UiIcon from "@dpa-id-components/ui-icon";

export default defineComponent({
  components: {
    UiInfoContent,
    UiIcon
  },
  props: {},

  setup() {
    return {};
  }
});
