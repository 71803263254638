







import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    /**
     * A string that contains HTML markers for text highlights, e.g.
     * @example lorem <em class="highlight">ipsum</em> dolor
     */
    highlight: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: "p"
    }
  }
});
