













import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    totalResults: {
      type: Number,
      required: true
    },
    maxDisplayableResults: {
      type: Number,
      default: 1000
    }
  }
});
