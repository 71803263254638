
























































































import uuidv1 from "uuid";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  inject
} from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";
import store from "@/store";
import router from "@/router";
import useImageUpload from "@/composition/useImageUpload";
import useUpdateImageIdentifier from "@/composition/useUpdateImageIdentifier";
import useBreakpoints from "@/composition/useBreakpoints";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiInput,
    UiTextArea,
    UiToggle,
    UiButton,
    UiErrorMessage
  },
  setup(_, { emit }) {
    const title = ref("");
    const description = ref("");
    const imageIdentifier = ref("Einigung_auf_EU_Klim_69147033");
    const isItemPublic = ref(false);
    const inputRef = ref();

    const {
      imageFile,
      imageUrl,
      imageName,
      handleSelectedImage
    } = useImageUpload();

    const { handleImageIdentifierUpload } = useUpdateImageIdentifier();

    const { isMobile } = useBreakpoints();

    const filterRules = computed(() => store.state.filterRules.filterRules);

    const userProfile = computed(() => store.state.user?.profile);

    const curatedSearchFilter = computed(() => ({
      id: uuidv1(),
      name: title.value,
      description: description.value,
      imageIdentifier: imageName ? imageName.value : imageIdentifier.value,
      owner: userProfile.value?.sub,
      filterRules: filterRules.value,
      curatedSearch: true,
      isCuratedSearchActive: isItemPublic.value
    }));

    const eventBus: Vue = inject("eventBus");

    function onClearSelectedImage() {
      imageFile.value = "";
      imageName.value = "";
      imageUrl.value = "";
    }

    const save = () => {
      const authJWT = store.state.auth.token;

      store.dispatch("filters/saveFilter", {
        filter: curatedSearchFilter.value,
        authJWT
      });

      router.push({
        query: {
          ...router.currentRoute.query,
          filterId: curatedSearchFilter.value.id
        }
      });

      // upload ImageIdentifier
      handleImageIdentifierUpload(imageFile, imageName);

      eventBus.$emit("show", {
        title: "Kuratierte Suche gespeichert",
        icon: "checkmark"
      });
      emit("close");
    };

    onMounted(() => {
      inputRef.value?.$refs?.input?.focus();
    });

    return {
      title,
      description,
      isItemPublic,
      curatedSearchFilter,
      save,
      inputRef,
      isMobile,
      handleSelectedImage,
      imageUrl,
      imageFile,
      imageName,
      handleImageIdentifierUpload,
      onClearSelectedImage
    };
  }
});
