










import { defineComponent } from "@/composition-api";
import diamondIcon from "@/assets/vectors/icons/diamond.svg?inline";

export default defineComponent({
  components: { diamondIcon }
});
