import { computed, ref, watch } from "@/composition-api";
import router from "@/router";
import store from "@/store";
import { searchCuratedSearchNames } from "@/store/modules/filters/actions";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import compareDesc from "date-fns/compareDesc";
import deepEqual from "fast-deep-equal";
import useAuthentication from "./useAuthentication";
import useFetchPlaylists from "./useFetchPlaylists";
import useFilterRulesCount from "./useFilterRulesCount";

export default () => {
  const { appliedFullTextRules } = useFilterRulesCount();
  const { query } = useRouter(router)();
  const { fetchPlaylists } = useFetchPlaylists();
  const { validateAndRetrieveToken } = useAuthentication();
  const searchPublicLists = ref([]);

  const searchPlaylistNames = computed(
    () =>
      !query.value.playlistId &&
      appliedFullTextRules.value?.map(({ values }) => ({ values }))
  );

  watch(
    searchPlaylistNames,
    async (val, oldVal) => {
      if (!deepEqual(val, oldVal)) {
        const search = searchPlaylistNames.value;
        const searchPlaylists = (await fetchPlaylists(search))
          .filter(({ id }) => query.value.playlistId !== id)
          .map(
            ({
              name,
              imageIdentifier,
              description,
              updatedAt,
              id,
              isPlaylistActive,
              order
            }) => ({
              label: name,
              src: `${store.state.stageconfig.config.curatedSearchImageURL}/${imageIdentifier}`,
              id,
              description,
              updatedAt: new Date(updatedAt),
              isPlaylistActive,
              order,
              to: {
                query: {
                  playlistId: id
                }
              }
            })
          );

        const curatedSearches = search?.length
          ? (
              await searchCuratedSearchNames({
                authJWT: validateAndRetrieveToken(),
                search
              })
            )
              .filter(({ id }) => query.value.filterId !== id)
              .map(
                ({
                  name,
                  imageIdentifier,
                  description,
                  updatedAt,
                  id,
                  isCuratedSearchActive,
                  order
                }) => ({
                  label: name,
                  src: `${store.state.stageconfig.config.curatedSearchImageURL}/${imageIdentifier}`,
                  id,
                  description,
                  updatedAt: new Date(updatedAt),
                  isCuratedSearchActive,
                  order,
                  to: {
                    query: {
                      filterId: id
                    }
                  }
                })
              )
          : [];

        searchPublicLists.value = [
          ...searchPlaylists,
          ...curatedSearches
        ].sort((a, b) => compareDesc(a.updatedAt, b.updatedAt));
      }
    },
    { immediate: true, deep: true }
  );

  return {
    searchPublicLists,
    searchPlaylistNames
  };
};
