<template>
  <div class="items-center h-6">
    <a
      v-for="(keyword, index) in keywords"
      :key="`keyword-${index}`"
      :title="keyword"
      class="mr-4 text-xs whitespace-no-wrap transition duration-150 rounded-full cursor-pointer text-blue hover:text-blue-dark focus:text-blue-dark hover:underline focus-visible:outline-blue"
      href="#"
      @click.prevent="$emit('keywordClick', keywords[index])"
      >{{ keyword }}</a
    >
  </div>
</template>

<script>
import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    keywords: {
      type: Array,
      required: true
    }
  }
});
</script>
