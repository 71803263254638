import Vue from "vue";
import { computed, watch, Ref } from "@/composition-api";
import debounce from "lodash.debounce";
import store from "@/store";
import useBreakpoints from "./useBreakpoints";

const TRANSCRIPT_OFFSET = 90;
const CHAR_AVG_WIDTH = 6; // based on 1ch width text-xs font-size
const MAX_ROWS = 2; // to multiply number of characters
const ELLIPSIS_BUFFER = 10; // space for truncation ellipsis

export default (props: { contentTemplateRef: Ref }) => {
  const { contentTemplateRef } = props;

  /**
   * Refs and computed refs for fragmentSize calculation
   * Capitalised consts represent various width offsets or multipliers
   */
  const { screen, isDesktop } = useBreakpoints();
  const vw = computed(() => screen.value?.vw);

  // Estimate number of characters per transcriptline from the Feed component's width
  const getTranscriptCharatersPerLine = async () => {
    if (contentTemplateRef.value) {
      await Vue.nextTick();
      const { width } = contentTemplateRef.value.getBoundingClientRect();
      const transcriptCharatersPerLine =
        Math.round((width - TRANSCRIPT_OFFSET) / CHAR_AVG_WIDTH) -
        ELLIPSIS_BUFFER;

      return transcriptCharatersPerLine;
    }

    return 200; // default value
  };

  // fragmentSize based on characters per line and current breakpoint
  const setFragmentSize = () => {
    getTranscriptCharatersPerLine().then((characters: number) => {
      const fragmentSize = isDesktop.value ? characters : MAX_ROWS * characters;

      store.commit("audioitems/setFragmentSize", fragmentSize);
    });
  };

  // Watcher on window resize (screen.vw as proxy)
  watch(
    vw,
    debounce(() => {
      if (vw.value) {
        setFragmentSize();
      }
    }),
    { immediate: true }
  );
};
