import { render, staticRenderFns } from "./Feed.vue?vue&type=template&id=76d7abfb&scoped=true&"
import script from "./Feed.vue?vue&type=script&lang=ts&"
export * from "./Feed.vue?vue&type=script&lang=ts&"
import style0 from "./Feed.vue?vue&type=style&index=0&id=76d7abfb&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d7abfb",
  null
  
)

export default component.exports