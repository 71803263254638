

















import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted
} from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";

export default defineComponent({
  setup() {
    const lineClampEl = ref();

    const state = reactive({
      isOpen: false
    });

    const { screen, isDesktop } = useBreakpoints();

    const NUMBER_OF_LINES = 5;
    const LINE_HEIGHT = 21;
    const BOTTOM_MARGIN = 8;

    const overflow = computed(() => {
      return state.isOpen ? "initial" : "hidden";
    });

    const maxHeight = computed(() => {
      return state.isOpen ? "none" : `${NUMBER_OF_LINES * LINE_HEIGHT}px`;
    });

    const style = computed(() => {
      return {
        maxHeight: maxHeight.value,
        overflow: overflow.value
      };
    });

    const toggle = () => {
      state.isOpen = !state.isOpen;
    };

    const isOverflown = ref(false);
    onMounted(() => {
      const { height } =
        (lineClampEl.value as HTMLElement).firstElementChild.getBoundingClientRect() ||
        {};
      isOverflown.value =
        height > BOTTOM_MARGIN + NUMBER_OF_LINES * LINE_HEIGHT;
    });

    return {
      state,
      style,
      screen,
      isDesktop,
      toggle,
      isOverflown,
      lineClampEl
    };
  }
});
