
























































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
  inject
} from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiErrorMessage from "@/components/ui/UiErrorMessage.vue";
import store from "@/store";
import useImageUpload from "@/composition/useImageUpload";
import useUpdateImageIdentifier from "@/composition/useUpdateImageIdentifier";
import useBreakpoints from "@/composition/useBreakpoints";
import router from "@/router";

import { selectedGuids } from "./playlistState";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiInput,
    UiTextArea,
    UiToggle,
    UiButton,
    UiErrorMessage
  },
  setup() {
    const eventBus: Vue = inject("eventBus");
    const title = ref("");
    const description = ref("");
    const imageIdentifier = ref("Einigung_auf_EU_Klim_69147033");
    const isItemPublic = ref(false);
    const inputRef = ref();

    const {
      imageFile,
      imageUrl,
      imageName,
      handleSelectedImage
    } = useImageUpload();
    const { handleImageIdentifierUpload } = useUpdateImageIdentifier();
    const { isMobile } = useBreakpoints();

    const createPlaylist = computed(() => ({
      name: title.value,
      description: description.value,
      imageIdentifier: imageName ? imageName.value : imageIdentifier.value,
      audioItems: selectedGuids.value,
      isPlaylistActive: isItemPublic.value
    }));

    const closeDialog = () => {
      store.commit("playlists/setShowCreatePlaylistDialog", false);
    };

    function onClearSelectedImage() {
      imageFile.value = "";
      imageName.value = "";
      imageUrl.value = "";
    }
    const save = () => {
      const authJWT = store.state.auth.token;
      store.dispatch("playlists/savePlaylist", {
        playlist: createPlaylist.value,
        authJWT
      });

      // upload ImageIdentifier
      handleImageIdentifierUpload(imageFile, imageName);

      eventBus.$emit("show", {
        title: "Playlist gespeichert",
        icon: "checkmark"
      });
      closeDialog();
      setTimeout(() => {
        router.push({ path: "/playlists" });
      }, 1500);
    };

    onMounted(() => {
      inputRef.value?.$refs?.input?.focus();
    });

    return {
      title,
      description,
      isItemPublic,
      inputRef,
      isMobile,
      imageUrl,
      imageFile,
      imageName,
      save,
      handleSelectedImage,
      handleImageIdentifierUpload,
      onClearSelectedImage,
      closeDialog
    };
  }
});
